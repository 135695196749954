import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useMemo } from "react";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../globals/constants";

export const useMyPatientBookings = (type, filters, take, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const results = useInfiniteQuery({
		queryKey: [`${type}Bookings`, currentClinician, filters],
		queryFn: async ({ pageParam = 0 }) => {
			const data = await getMyPatientBookings(
				currentDataSource?.DataSourceId,
				currentClinician,
				type,
				filters,
				pageParam,
				take,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchInterval: widgetRefetchInterval,
		enabled: !!currentClinician && enabled && !isNaN(take),
	});

	const flatData = useMemo(() => results.data?.pages?.flatMap((page) => page?.data), [results?.data]);

	return { ...results, data: flatData, type: type };
};

const getMyPatientBookings = async (dataSourceId, clinicianId, type, filters, skip = 0, take) => {
	const searchParams = new URLSearchParams();
	for (const [filterName, filter] of Object.entries(filters)) {
		filter.getEndpointValue().forEach((value) => {
			searchParams.append(filterName, value);
		});
	}
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/mypatients/${type}?skip=${skip}&take=${take + 1}${
			searchParams.size > 0 ? `&${searchParams.toString()}` : ""
		}`,
		{},
		"v2",
	);
	if (data?.request?.response) throw new Error("Getting bookings failed");
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};

export const useMyPatientCounts = (filters, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const results = useQuery({
		queryKey: [`MyPatientCounts`, currentClinician, filters],
		queryFn: async () => {
			const searchParams = new URLSearchParams();
			for (const [filterName, filter] of Object.entries(filters)) {
				filter.getEndpointValue().forEach((value) => {
					if (filterName !== "status") searchParams.append(filterName, value);
				});
			}
			const data = await makeApiRequest(
				`${currentDataSource.DataSourceId}/clinician/${currentClinician}/mypatients/statuscounts?${
					searchParams.size > 0 ? `&${searchParams.toString()}` : ""
				}`,
				{},
				"v2",
			);
			if (data?.request?.response) throw new Error("Getting booking counts failed");
			return {
				Due: data.none + data.booked,
				Waiting: data.arrived,
				"In progress": data.attended,
				Completed: data.completed,
				"Cancelled/DNA": data.dna + data.cancelled,
			};
		},
		refetchInterval: widgetRefetchInterval,
		enabled: !!currentDataSource && !!currentClinician && enabled,
	});

	return results;
};
