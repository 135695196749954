import { Divider } from "@streets-heaver/shui2";
import { Name } from "../Sections/Name/Name";
import { Identity } from "../Sections/Identity/Identity";
import { Identifiers } from "../Sections/Identifiers/Identifiers";
import { Contact } from "../Sections/Contact/Contact";
import { NextOfKin } from "../Sections/NextOfKin/NextOfKin";
import { Shared } from "../Sections/Shared/Shared";
import { useFormContext } from "react-hook-form";
import classes from "./PatientForm.module.scss";

export const AddPatientFormContent = () => {
	const methods = useFormContext();
	const automationId = "add-patient";

	const title = methods.register("name.primary.title");
	const surname = methods.register("name.primary.surname");
	const forename = methods.register("name.primary.forename");

	return (
		<div className={classes.form}>
			<Name title={title} surname={surname} forename={forename} automationId={automationId} />
			<Divider />
			<Identity automationId={automationId} />
			<Divider />
			<Contact automationId={automationId} />
			<Divider />
			<Identifiers automationId={automationId} />
			<Divider />
			<NextOfKin automationId={automationId} />
			<Divider />
			<Shared automationId={automationId} />
		</div>
	);
};
