import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";

export const useAppointmentForCancel = (appointmentId, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	const query = useQuery({
		queryKey: ["appointmentForCancel", currentClinician, appointmentId],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/booking/${appointmentId}/cancel/load`,
			);
			if (data?.request?.response) throw new Error("Getting appointment details Failed");
			return data;
		},
		gcTime: 0,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!appointmentId && enabled,
	});

	return query;
};
