import classes from "./AssociatedBookingsDialog.module.scss";
import { Button, Dialog } from "@streets-heaver/shui2";

export const AssociatedBookingsDialog = ({ onSubmit, visible, setVisible, automationId }) => (
	<Dialog
		visible={visible}
		header="Linked booking"
		onOutsideClick={() => setVisible(false)}
		onCloseButtonClick={() => setVisible(false)}
	>
		<div className={classes.content}>
			<p>
				This booking is linked to others. Would you like to cancel all linked bookings at status booked or just the
				selected booking?
			</p>
			<div className={classes.buttons}>
				<Button
					type="primary"
					takeContainerWidth
					onClick={() => {
						onSubmit(true);
						setVisible(false);
					}}
					automationId={`${automationId}-cancel-all`}
				>
					Cancel all linked
				</Button>
				<Button
					type="primary"
					takeContainerWidth
					onClick={() => {
						onSubmit(false);
						setVisible(false);
					}}
					automationId={`${automationId}-cancel-individual`}
				>
					Cancel individual
				</Button>
				<Button takeContainerWidth onClick={() => setVisible(false)}>
					Close
				</Button>
			</div>
		</div>
	</Dialog>
);
