import { z } from "zod";
import {
	addressLine1SchemaError,
	addressLine2SchemaError,
	addressLine3SchemaError,
	countySchemaError,
	townCitySchemaError,
	postcodeSchemaError,
	emailSchemaError,
	mobileSchemaError,
	workSchemaError,
	mainSchemaError,
	emailLengthSchemaError,
} from "../../../../../globals/messages";
import { emailRegex, phoneNumberRegex, postcodeRegex } from "../../../../../globals/regex";

const addressLine1Schema = z.nullable(z.string().max(72, { message: addressLine1SchemaError }));
const addressLine2Schema = z.nullable(z.string().max(50, { message: addressLine2SchemaError }));
const addressLine3Schema = z.nullable(z.string().max(50, { message: addressLine3SchemaError }));
const townCitySchema = z.nullable(z.string().max(50, { message: townCitySchemaError }));
const countySchema = z.nullable(z.string().max(50, { message: countySchemaError }));
const postcodeSchema = z
	.nullable(
		z
			.string()
			.regex(postcodeRegex, {
				message: postcodeSchemaError,
			})
			.or(z.string().length(0)),
	)
	.optional();

export const emailSchema = z.union([
	z.string().regex(emailRegex, { message: emailSchemaError }).max(100, { message: emailLengthSchemaError }),
	z.string().length(0),
	z.null(),
]);
export const mobilePhoneSchema = z.nullable(
	z
		.string()
		.regex(phoneNumberRegex, { message: mobileSchemaError })
		.max(30, { message: mobileSchemaError })
		.or(z.string().length(0))
		.optional(),
);
export const workPhoneSchema = z.nullable(
	z
		.string()
		.regex(phoneNumberRegex, { message: workSchemaError })
		.max(30, { message: mobileSchemaError })
		.or(z.string().length(0))
		.optional(),
);
export const mainPhoneSchema = z.nullable(
	z
		.string()
		.regex(phoneNumberRegex, { message: mainSchemaError })
		.max(30, { message: mobileSchemaError })
		.or(z.string().length(0))
		.optional(),
);

export const addressSchema = z.object({
	addressLine1: addressLine1Schema,
	addressLine2: addressLine2Schema,
	addressLine3: addressLine3Schema,
	addressLine4: townCitySchema,
	addressLine5: countySchema,
	postcode: postcodeSchema,
	country: z.nullable(z.object({ name: z.string(), recordId: z.union([z.number(), z.string()]) })).optional(),
});

export const contactMethodSchema = {
	email: emailSchema,
	mobilePhone: mobilePhoneSchema,
	workPhone: workPhoneSchema,
	mainPhone: mainPhoneSchema,
};

export const contactSchema = {
	address: addressSchema,
	...contactMethodSchema,
	notificationMethodConfirmed: z.boolean(),
	preferredNotificationMethod: z.nullable(
		z.discriminatedUnion("value", [
			z.object({ value: z.literal("1"), label: z.string(), api: z.string() }),
			z.object({ value: z.literal("2"), label: z.string(), api: z.string() }),
			z.object({ value: z.literal("3"), label: z.string(), api: z.string() }),
			z.object({ value: z.literal("4"), label: z.string(), api: z.string() }),
		]),
	),
	preferredWrittenMethod: z.union([z.literal("Post"), z.literal("Email")]),
};
