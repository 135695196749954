import { faCalendarDays, faClipboardList, faUsersRectangle } from "@shfortawesome/pro-duotone-svg-icons";
import { faPlus } from "@shfortawesome/pro-regular-svg-icons";

export const apps = (userSecurityFlags) => {
	const clinicianPages = [];

	if (userSecurityFlags?.includes(`mypatients`))
		clinicianPages.push({
			title: "My patients",
			icon: faUsersRectangle,
			to: "mypatients",
			actionText: "Open",
		});

	if (userSecurityFlags?.includes(`patient-addpatient`))
		clinicianPages.push({
			title: "Add new patient",
			icon: faPlus,
			to: "add-patient",
			isActivity: true,
			actionText: "Add",
		});

	if (userSecurityFlags?.includes(`orders`))
		clinicianPages.push({
			title: "Orders",
			icon: faClipboardList,
			to: "orders",
			actionText: "Open",
		});

	if (userSecurityFlags?.includes(`scheduler`))
		clinicianPages.push({
			title: "Scheduler",
			icon: faCalendarDays,
			to: "scheduler",
			actionText: "Open",
		});

	if (userSecurityFlags?.includes(`appointment-addappointment`))
		clinicianPages.push({
			title: "Add new appointment",
			icon: faPlus,
			to: "add-appointment/new",
			isActivity: true,
			actionText: "Add",
		});

	if (userSecurityFlags?.includes(`appointment-addappointment`))
		clinicianPages.push({
			title: "Add follow-up appointment",
			icon: faPlus,
			to: "add-appointment/follow-up",
			isActivity: true,
			actionText: "Add",
		});

	return clinicianPages;
};
