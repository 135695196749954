import { Dialog } from "@streets-heaver/shui2";

export const DiscardChangesDialog = ({ onClose, visible, setVisible }) => (
	<Dialog
		visible={visible}
		onCloseButtonClick={() => setVisible(false)}
		onOutsideClick={() => setVisible(false)}
		header="Discard form?"
		buttons={{
			primaryButton: {
				children: "Yes, discard",
				onClick: () => {
					setVisible(false);
					onClose();
				},
			},
			secondaryButton: {
				children: "Return to form",
				onClick: () => setVisible(false),
			},
		}}
	>
		You are about to leave this page and lose all unsubmitted data. Are you sure?
	</Dialog>
);
