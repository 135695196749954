import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";

export const useCreateAppointment = () => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	return useMutation({
		mutationKey: ["createAppointment"],
		mutationFn: (data) => {
			return makeApiRequest(`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/bookings/create`, {
				method: "post",
				body: data,
			});
		},
	});
};
