import { usePurchasersLookup } from "../../../api/hooks";
import { purchaserHelperText } from "../../../globals/messages";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const PurchasersLookup = ({ label = "Purchaser", name = "purchaser", isReadOnly, automationId }) => {
	const form = useFormContext();
	const siteValue = form.watch("site")?.siteId;
	const [searchTerm, setSearchTerm] = useState("");
	const query = usePurchasersLookup(siteValue, searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			query={query}
			disabled={!siteValue || isReadOnly}
			automationId={automationId}
			helperText={!isReadOnly && purchaserHelperText}
		/>
	);
};
