import { Controller, useFormContext } from "react-hook-form";
import { NextOfKinField } from "../../../NextOfKinForm/NextOfKinField";

export const NextOfKin = ({ automationId }) => {
	const form = useFormContext();

	const nextOfKinFieldName = "nextOfKin";

	return (
		<div data-section id="nextOfKin">
			<Controller
				name={nextOfKinFieldName}
				control={form.control}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<NextOfKinField onChange={onChange} data={value} error={error} automationId={`${automationId}-nok`} />
				)}
			/>
		</div>
	);
};
