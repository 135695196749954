import classes from "./LoadingSpinner.module.scss";
import clsx from "clsx";

export const LoadingSpinner = (props) => (
	<svg
		width="35"
		height="40"
		viewBox="0 0 35 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className={clsx(classes.loadingIcon, props.className)}
	>
		<defs>
			<linearGradient id="color-0">
				<stop style={{ stopColor: "rgb(255, 255, 255)" }} offset="0"></stop>
				<stop offset="0.4"></stop>
				<stop offset="0.6"></stop>
				<stop offset="1" style={{ stopColor: "rgb(255, 255, 255)" }}></stop>
			</linearGradient>
			<linearGradient
				id="color-0-0"
				gradientUnits="userSpaceOnUse"
				x1="23.888"
				y1="5.443"
				x2="23.888"
				y2="41.687"
				gradientTransform="matrix(0, 0.852645, -1.111895, 0, 50.100073, 3.453129)"
				xlinkHref="#color-0"
			></linearGradient>

			<mask id="mask-element">
				<path
					d="M21.875 23.75C22.0859 23.75 22.2891 23.7578 22.5 23.7656V28.2812C20.3438 28.8359 18.75 30.7969 18.75 33.125V36.25C18.75 36.9375 19.3125 37.5 20 37.5H21.25C21.9375 37.5 22.5 36.9375 22.5 36.25C22.5 35.5625 21.9375 35 21.25 35V33.125C21.25 31.7422 22.3672 30.625 23.75 30.625C25.1328 30.625 26.25 31.7422 26.25 33.125V35C25.5625 35 25 35.5625 25 36.25C25 36.9375 25.5625 37.5 26.25 37.5H27.5C28.1875 37.5 28.75 36.9375 28.75 36.25V33.125C28.75 30.7969 27.1562 28.8359 25 28.2812V24.125C30.7422 25.5234 35 30.7031 35 36.875V37.5C35 38.8828 33.8828 40 32.5 40H2.5C1.11719 40 0 38.8828 0 37.5V36.875C0 30.7031 4.25781 25.5234 10 24.125V28.9297C8.19531 29.4687 6.875 31.1406 6.875 33.125C6.875 35.5391 8.83594 37.5 11.25 37.5C13.6641 37.5 15.625 35.5391 15.625 33.125C15.625 31.1406 14.3047 29.4687 12.5 28.9297V23.7656C12.7109 23.7578 12.9141 23.75 13.125 23.75H21.875ZM11.25 31.25C11.7473 31.25 12.2242 31.4475 12.5758 31.7992C12.9275 32.1508 13.125 32.6277 13.125 33.125C13.125 33.6223 12.9275 34.0992 12.5758 34.4508C12.2242 34.8025 11.7473 35 11.25 35C10.7527 35 10.2758 34.8025 9.92417 34.4508C9.57254 34.0992 9.375 33.6223 9.375 33.125C9.375 32.6277 9.57254 32.1508 9.92417 31.7992C10.2758 31.4475 10.7527 31.25 11.25 31.25Z"
					fill="white"
				/>
				<path
					d="M27.5 10C27.5 12.6522 26.4464 15.1957 24.5711 17.0711C22.6957 18.9464 20.1522 20 17.5 20C14.8478 20 12.3043 18.9464 10.4289 17.0711C8.55357 15.1957 7.5 12.6522 7.5 10C7.5 7.34784 8.55357 4.8043 10.4289 2.92893C12.3043 1.05357 14.8478 0 17.5 0C20.1522 0 22.6957 1.05357 24.5711 2.92893C26.4464 4.8043 27.5 7.34784 27.5 10Z"
					fill="white"
				/>

				<path
					className={classes.mask}
					d="M 44.049 0 L 44.049 41.716 L 3.749 41.716 L 3.749 0 L 44.049 0 Z"
					style={{
						fill: "url('#color-0-0')",
					}}
				/>
			</mask>
		</defs>
		<g>
			<path
				mask="url(#mask-element)"
				d="M21.875 23.75C22.0859 23.75 22.2891 23.7578 22.5 23.7656V28.2812C20.3438 28.8359 18.75 30.7969 18.75 33.125V36.25C18.75 36.9375 19.3125 37.5 20 37.5H21.25C21.9375 37.5 22.5 36.9375 22.5 36.25C22.5 35.5625 21.9375 35 21.25 35V33.125C21.25 31.7422 22.3672 30.625 23.75 30.625C25.1328 30.625 26.25 31.7422 26.25 33.125V35C25.5625 35 25 35.5625 25 36.25C25 36.9375 25.5625 37.5 26.25 37.5H27.5C28.1875 37.5 28.75 36.9375 28.75 36.25V33.125C28.75 30.7969 27.1562 28.8359 25 28.2812V24.125C30.7422 25.5234 35 30.7031 35 36.875V37.5C35 38.8828 33.8828 40 32.5 40H2.5C1.11719 40 0 38.8828 0 37.5V36.875C0 30.7031 4.25781 25.5234 10 24.125V28.9297C8.19531 29.4687 6.875 31.1406 6.875 33.125C6.875 35.5391 8.83594 37.5 11.25 37.5C13.6641 37.5 15.625 35.5391 15.625 33.125C15.625 31.1406 14.3047 29.4687 12.5 28.9297V23.7656C12.7109 23.7578 12.9141 23.75 13.125 23.75H21.875ZM11.25 31.25C11.7473 31.25 12.2242 31.4475 12.5758 31.7992C12.9275 32.1508 13.125 32.6277 13.125 33.125C13.125 33.6223 12.9275 34.0992 12.5758 34.4508C12.2242 34.8025 11.7473 35 11.25 35C10.7527 35 10.2758 34.8025 9.92417 34.4508C9.57254 34.0992 9.375 33.6223 9.375 33.125C9.375 32.6277 9.57254 32.1508 9.92417 31.7992C10.2758 31.4475 10.7527 31.25 11.25 31.25Z"
				fill="#BA2F79"
			/>
			<path
				mask="url(#mask-element)"
				opacity="0.4"
				d="M27.5 10C27.5 12.6522 26.4464 15.1957 24.5711 17.0711C22.6957 18.9464 20.1522 20 17.5 20C14.8478 20 12.3043 18.9464 10.4289 17.0711C8.55357 15.1957 7.5 12.6522 7.5 10C7.5 7.34784 8.55357 4.8043 10.4289 2.92893C12.3043 1.05357 14.8478 0 17.5 0C20.1522 0 22.6957 1.05357 24.5711 2.92893C26.4464 4.8043 27.5 7.34784 27.5 10Z"
				fill="#BA2F79"
			/>
		</g>
	</svg>
);
