import classes from "./WidgetGrid.module.scss";
import clsx from "clsx";

export function WidgetGrid({ align = "center", className, children }) {
	return <div className={clsx(classes.widgetGrid, classes[align], className)}>{children}</div>;
}

WidgetGrid.Item = function WidgetGridItem({ positionX, positionY, width, height, automationId, children }) {
	return (
		<div
			style={{
				gridColumn: `span ${width.toString()} / span ${width.toString()}`,
				gridRow: `span ${height.toString()}/ span ${height.toString()}`,
				gridColumnStart: positionX.toString(),
				gridRowStart: positionY.toString(),
			}}
			data-testid={automationId}
		>
			{children}
		</div>
	);
};
