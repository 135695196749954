import { NavLink } from "@streets-heaver/shui2";
import { Controller, useFormContext } from "react-hook-form";
import { Radio } from "../../../../Radio/Radio";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { genderOptions } from "../../../../../globals/defaultOptions";
import { FormDatebox } from "../../../FormDatebox/FormDatebox";

export const Identity = ({ automationId }) => {
	const form = useFormContext();

	const dateOfBirthName = "identity.dateOfBirth";
	const genderName = "identity.gender";

	return (
		<div data-section id="identity">
			<FormGroup title="Identity">
				<FormField label="Date of birth" name={dateOfBirthName}>
					<Controller
						name={dateOfBirthName}
						control={form.control}
						render={({ field: { value, onChange, error } }) => (
							<FormDatebox
								value={value}
								onChange={onChange}
								isError={error}
								portalTarget={document.body}
								automationId={`${automationId}-dateOfBirth`}
							/>
						)}
					/>
				</FormField>

				<FormField
					label="Gender (stated)"
					helperText={
						<>
							The patient&apos;s gender. This links to the protected (noneditable) Genders code table which is populated
							with values from the{" "}
							<NavLink
								to="https://www.datadictionary.nhs.uk/attributes/person_stated_gender_code.html"
								style={{ fontSize: "12px" }}
								newTab
							>
								NHS data Dictionary
							</NavLink>
							.
						</>
					}
					isMandatory
					name={genderName}
				>
					<Controller
						name={genderName}
						control={form.control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Radio
								items={genderOptions}
								value={value?.value}
								onChange={(e) => onChange(e)}
								isError={error}
								automationId={`${automationId}-gender`}
							/>
						)}
					/>
				</FormField>
			</FormGroup>
		</div>
	);
};
