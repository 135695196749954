import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ComingSoon.module.scss";

export const ComingSoon = ({ title, icon, colour = "var(--appColour)" }) => {
	return (
		<div className={classes.comingSoon}>
			<div className={classes.comingSoonTitles}>
				<FontAwesomeIcon
					icon={icon}
					className={classes.icon}
					style={{
						"--fa-primary-color": colour,
						"--fa-secondary-color": colour,
					}}
				/>
				<p className={classes.title}>{title}</p>
			</div>
			<p className={classes.subtitle}>Coming soon</p>
		</div>
	);
};
