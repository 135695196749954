import { z } from "zod";
import { cancellationReasonSchemaError, outcomeSchemaError } from "../../globals/messages";

export const cancelAppointmentSchema = z.object({
	outcome: z.object({ recordId: z.union([z.number(), z.string()]) }, { message: outcomeSchemaError }),
	cancellationReason: z.object(
		{ recordId: z.union([z.number(), z.string()]) },
		{ message: cancellationReasonSchemaError },
	),
});
