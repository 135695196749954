import { FormGroup } from "../../../FormGroup/FormGroup.jsx";
import { PracticesLookup } from "../../../lookups/PracticesLookup.jsx";
import { ReferrersLookup } from "../../../lookups/ReferrersLookup.jsx";
import { RegisteredGPsLookup } from "../../../lookups/RegisteredGPsLookup.jsx";

export const Shared = ({ automationId }) => {
	const registeredGPName = "sharedDetails.registeredGP";
	const gpPracticeName = "sharedDetails.gpPractice";
	const registeredOpticianName = "sharedDetails.registeredOptician";
	const opticianPracticeName = "sharedDetails.opticianPractice";

	return (
		<div data-section id="shared">
			<FormGroup title="Shared details">
				<RegisteredGPsLookup label="Registered GP" name={registeredGPName} automationId={automationId} />
				<PracticesLookup label="GP practice" name={gpPracticeName} automationId={automationId} />
				<ReferrersLookup label="Registered optician" name={registeredOpticianName} automationId={automationId} />
				<PracticesLookup label="Optician practice" name={opticianPracticeName} automationId={automationId} />
			</FormGroup>
		</div>
	);
};
