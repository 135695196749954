import { Dropdown, Lookup } from "@streets-heaver/shui2";
import { Switch, Timebox } from "@streets-heaver/shui2/inputs";
import { Controller, useFormContext } from "react-hook-form";
import { timeRegex } from "../../../../../globals/regex";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useContactTypesLookup } from "../../../../../api/hooks";
import dayjs from "dayjs";
import { defaultDurationOptions } from "../../../../../globals/defaultOptions";
import { contactTypeRefinementError } from "../../../../../globals/messages";

export const EditBooking = ({ booking, automationId }) => {
	const form = useFormContext();

	const arrivedName = "arrived";

	const durationName = "duration";
	const isFaceToFaceName = "isFaceToFace";
	const contactTypeName = "contactType";

	const contactTypeQuery = useContactTypesLookup();

	const isDue = booking?.status === "Due";
	const isArrived = booking?.status === "Arrived";
	const isCompleted = booking?.status === "Completed";
	const isBookingToday = dayjs().isSame(booking?.start, "day");
	const isArrivedDisabled = !(isDue || isArrived) || !isBookingToday;

	return (
		<FormGroup>
			<div style={{ width: 160 }}>
				<FormField
					label="Arrived"
					action={{
						actionOnClick: () => form.setValue(arrivedName, dayjs().format("HH:mm")),
						actionText: "Now",
						actionIsDisabled: isArrivedDisabled,
					}}
					automationId={`${automationId}-arrivedTime-field`}
				>
					<Controller
						name={arrivedName}
						control={form.control}
						rules={{ pattern: timeRegex }}
						render={({ field: { onChange, value, ref } }) => (
							<Timebox
								type="filledDarker"
								size="large"
								dropdownStep={5}
								inputStep={5}
								value={value}
								onChange={onChange}
								ref={ref}
								automationId={`${automationId}-arrivedTime`}
								disabled={isArrivedDisabled}
							/>
						)}
					/>
				</FormField>
			</div>
			<FormField label="Duration" name={durationName} isMandatory>
				<Controller
					name={durationName}
					render={({ field: { value, onChange } }) => (
						<div style={{ width: 200 }}>
							<Dropdown
								placeholder={{ label: "Select a duration" }}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								size="large"
								options={defaultDurationOptions}
								portalTarget={document.body}
								automationId={`${automationId}-duration`}
								disabled={isCompleted}
							/>
						</div>
					)}
				/>
			</FormField>
			<FormField label="Is face to face?" isMandatory name={isFaceToFaceName}>
				<Controller
					control={form.control}
					name={isFaceToFaceName}
					render={({ field: { onChange, value } }) => (
						<Switch
							checked={value}
							onChange={onChange}
							label={value ? "Yes" : "No"}
							automationId={`${automationId}-isFaceToFace`}
						/>
					)}
				/>
			</FormField>
			{!form.watch(isFaceToFaceName) && (
				<FormField label="Contact type" name={contactTypeName} isMandatory>
					<Controller
						name={contactTypeName}
						control={form.control}
						rules={{ validate: { required: (value) => value || contactTypeRefinementError } }}
						render={({ field: { value, onChange } }) => (
							<div style={{ width: 340 }}>
								<Lookup
									dropdownMaxHeight={"calc(80vh - 190px)"}
									selected={value}
									setSelected={onChange}
									searchQueryData={contactTypeQuery}
									automationId={`${automationId}-contactType`}
								/>
							</div>
						)}
					/>
				</FormField>
			)}
		</FormGroup>
	);
};
