import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";
import { makeApiRequest } from "../../makeApiRequest";
import { useMemo } from "react";

export const useSitesLookup = (search) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "code-table", "sites", currentClinician, search],
		queryFn: async ({ pageParam }) => {
			return await getSites(currentDataSource?.DataSourceId, currentClinician, pageParam, 12, search);
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});

	const flatData = useMemo(() => query.data?.pages?.flatMap((page) => page?.data), [query?.data]);

	return { ...query, data: flatData };
};

export const useSitesLookupFromIds = (ids) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	return useQuery({
		queryKey: ["sitesLookupFromIds", currentClinician, ids],
		queryFn: async () => {
			if (!ids?.length) return [];
			return await getSites(currentDataSource?.DataSourceId, currentClinician, 0, ids.length, null, ids);
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
		staleTime: 60000,
	});
};

const getSites = async (dataSourceId, clinicianId, skip = 0, take, searchText = "", sites = []) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/lookup/sites?skip=${skip}&take=${take + 1}${
			searchText && searchText !== "" ? `&searchText=${encodeURIComponent(searchText)}` : ""
		}${sites.length ? sites.map((site) => `&site=${site}`).join("") : ""}`,
	);
	return {
		data: data.slice(0, take).map((site) => ({ ...site, id: site?.siteId })),
		nextPageIndex: data?.length >= take + 1 ? skip + take : undefined,
	};
};
