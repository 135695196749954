import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import classes from "./EditAppointment.module.scss";
import { ErrorDisplay, Spinner } from "@streets-heaver/shui2";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { FormWrapper, PatientBookingSelection, NoAccessPage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { useAppointmentForEdit } from "../../api/hooks";
import { transformAppointmentDataForForm } from "./transformAppointmentData";
import { EditAppointmentForm } from "./EditAppointmentForm";

export const EditAppointment = () => {
	const navigate = useNavigate();
	const { bookingId } = useParams();

	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const { userSecurityFlags } = useCurrentClinicians();

	const { data, isLoading, error } = useAppointmentForEdit(bookingId, !!bookingId);
	const [formattedData, setFormattedData] = useState();

	useEffect(() => {
		if (!selectedPatient) {
			setSelectedBooking(null);
		}
	}, [selectedPatient]);

	useEffect(() => {
		if (data) setFormattedData(transformAppointmentDataForForm(data));
	}, [data]);

	if (!userSecurityFlags.includes("appointment-editappointment")) {
		return <NoAccessPage />;
	}

	if (!bookingId)
		return (
			<FormWrapper header={"Edit an appointment"}>
				<FormWrapper.Body>
					<PatientBookingSelection
						selectedBooking={selectedBooking}
						setSelectedBooking={setSelectedBooking}
						selectedPatient={selectedPatient}
						setSelectedPatient={setSelectedPatient}
					/>
				</FormWrapper.Body>
				<FormWrapper.ButtonBar
					buttons={{
						primaryButton: {
							navigation: {
								to: selectedBooking
									? `${selectedBooking?.bookingUniqueId}`
									: selectedPatient
										? `../${ActionPaths.EditPatientPreselected(selectedPatient?.patientId)}`
										: undefined,
							},
							children: selectedBooking ? "Edit appointment" : "Edit patient",
							disabled: !selectedPatient,
						},
						secondaryButton: { onClick: () => navigate(-1), children: "Cancel" },
						tertiaryButton: {
							navigation: { to: `../${ActionPaths.CancelAppointmentPreselected(selectedBooking?.bookingUniqueId)}` },
							children: "Cancel appointment",
							disabled: !selectedBooking,
						},
					}}
				/>
			</FormWrapper>
		);

	if (error)
		return (
			<FormWrapper>
				<FormWrapper.Body>
					<div className={classes.formContent}>
						<ErrorDisplay title="Booking not found">Unable to find this booking.</ErrorDisplay>
					</div>
				</FormWrapper.Body>
			</FormWrapper>
		);

	if (bookingId && formattedData && !isLoading) return <EditAppointmentForm data={formattedData} />;

	return (
		<FormWrapper>
			<FormWrapper.Body>
				<div className={classes.formContent}>
					<Spinner size="huge" text="Loading appointment details" />
				</div>
			</FormWrapper.Body>
		</FormWrapper>
	);
};
