import { useFormError } from "../../../../../utils";
import { SegmentedControl, Textbox } from "@streets-heaver/shui2";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { preferredWrittenMethodOptions } from "../../../../../globals/defaultOptions";
import { PreferredNotification } from "./PreferredNotification";
import { AdditionalPhoneNumbers } from "./AdditionalPhoneNumbers";
import { Address } from "./Address/Address";

export const Contact = ({ automationId }) => {
	const form = useFormContext();

	const lineOne = form.register("contact.primary.address.addressLine1");
	const lineTwo = form.register("contact.primary.address.addressLine2");
	const lineThree = form.register("contact.primary.address.addressLine3");
	const townCity = form.register("contact.primary.address.addressLine4");
	const county = form.register("contact.primary.address.addressLine5");
	const postcode = form.register("contact.primary.address.postcode");
	const countryFieldName = "contact.primary.address.country";
	const email = form.register("contact.primary.email");
	const mobilePhone = form.register("contact.primary.mobilePhone");
	const workPhone = form.register("contact.primary.workPhone");
	const mainPhone = form.register("contact.primary.mainPhone");

	const notificationMethodConfirmed = form.register("contact.primary.notificationMethodConfirmed");
	const preferredNotificationMethod = form.register("contact.primary.preferredNotificationMethod");
	const preferredWrittenMethod = form.register("contact.primary.preferredWrittenMethod");

	const address = form.register("contact.primary.address", { deps: [lineOne.name, postcode.name] });

	const preferredWrittenMethodField = useWatch({ name: preferredWrittenMethod.name });
	const preferredNotificationMethodField = useWatch({ name: preferredNotificationMethod.name });
	const notificationMethodConfirmedField = useWatch({ name: notificationMethodConfirmed.name });
	const isAddressMandatory = preferredWrittenMethodField === "Post";
	const isEmailMandatory =
		preferredWrittenMethodField === "Email" ||
		(notificationMethodConfirmedField && preferredNotificationMethodField?.label === "Email");
	const isMobilePhoneMandatory =
		notificationMethodConfirmedField &&
		(preferredNotificationMethodField?.label === "Mobile" ||
			preferredNotificationMethodField?.label === "SMS to mobile");
	const isMainPhoneMandatory =
		notificationMethodConfirmedField && preferredNotificationMethodField?.label === "SMS to main phone";

	return (
		<div data-section id="contact">
			<FormGroup title="Contact">
				<Address
					isMandatory={isAddressMandatory}
					control={form.control}
					address={address}
					addressLine1={lineOne}
					addressLine2={lineTwo}
					addressLine3={lineThree}
					townCity={townCity}
					county={county}
					postcode={postcode}
					countryFieldName={countryFieldName}
					automationId={automationId}
				/>
				<FormField label="Email" name={email.name} isMandatory={isEmailMandatory}>
					<Textbox
						type={"filledDarker"}
						inputName={email.name}
						onChange={email.onChange}
						onBlur={email.onBlur}
						reference={email.ref}
						size="large"
						isError={useFormError(email.name).length > 0}
						automationId={`${automationId}-email`}
					/>
				</FormField>
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Mobile Phone" name={mobilePhone.name} isMandatory={isMobilePhoneMandatory}>
						<Textbox
							type={"filledDarker"}
							inputName={mobilePhone.name}
							onChange={mobilePhone.onChange}
							onBlur={mobilePhone.onBlur}
							reference={mobilePhone.ref}
							size="large"
							isError={useFormError(mobilePhone.name).length > 0}
							automationId={`${automationId}-mobile`}
						/>
					</FormField>
				</div>

				<AdditionalPhoneNumbers
					mainPhoneField={useWatch({ name: mainPhone.name })}
					workPhoneField={useWatch({ name: workPhone.name })}
					workPhone={workPhone}
					mainPhone={mainPhone}
					isMainPhoneMandatory={isMainPhoneMandatory}
					hasMainPhoneError={useFormError(mainPhone.name).length > 0}
					hasWorkPhoneError={useFormError(workPhone.name).length > 0}
					automationId={automationId}
				/>

				<PreferredNotification
					notificationMethodConfirmed={notificationMethodConfirmed}
					preferredNotificationMethod={preferredNotificationMethod}
					mobilePhone={mobilePhone}
					email={email}
					mainPhone={mainPhone}
					automationId={automationId}
				/>

				<FormField label="Preferred written method" name={preferredWrittenMethod.name}>
					<div style={{ maxWidth: "240px" }}>
						<Controller
							name={preferredWrittenMethod.name}
							render={({ field: { value, onChange } }) => (
								<SegmentedControl
									onChange={(e) => {
										onChange(e);
										form.trigger([lineOne.name, postcode.name, email.name]);
									}}
									updateSelectedSegment={value}
									type="tabs"
									segments={preferredWrittenMethodOptions}
									automationId={`${automationId}-written-preferred`}
								/>
							)}
						/>
					</div>
				</FormField>
			</FormGroup>
		</div>
	);
};
