import { getIfEmptyOrNull } from "../../../../../utils/convertEmptyStringToNull";
import { nhsNoErrorText } from "../../../../../globals/messages";

export const verificationStatusNhsNumberRefinement = {
	condition: (val) =>
		!(
			(val.identifiers.verificationStatus?.value === "01" || val.identifiers.verificationStatus?.value === "02") &&
			getIfEmptyOrNull(val.identifiers.nhsNo)
		),
	args: {
		message: nhsNoErrorText,
		path: ["identifiers", "nhsNo"],
	},
};
