import { nameSchema } from "../PatientForms/Sections/Name/nameSchema";
import { dateOfBirthSchema } from "../PatientForms/Sections/Identity/identitySchema";
import { addressSchema, contactMethodSchema } from "../PatientForms/Sections/Contact/contactSchema";
import { z } from "zod";

export const individualNextOfKinSchema = z.object({
	name: z.object(nameSchema),
	relationship: z.object(
		{ name: z.string(), recordId: z.union([z.number(), z.string()]) },
		{ message: "Relationship is required and cannot be left blank." },
	),
	contact: z.object({ ...contactMethodSchema, address: addressSchema }),
	dateOfBirth: dateOfBirthSchema,
	notes: z.nullable(z.string()).optional(),
});

export const addNextOfKinFormDefaultValues = () => ({
	name: {
		surname: "",
		forename: "",
		title: "",
	},
	relationship: undefined,
	contact: {
		address: {
			addressLine1: "",
			addressLine2: "",
			addressLine3: "",
			addressLine4: "",
			addressLine5: "",
			postcode: "",
			country: undefined,
		},
		email: "",
		mobilePhone: "",
		mainPhone: "",
		workPhone: "",
	},
	dateOfBirth: null,
	notes: "",
});
