import { Datebox } from "@streets-heaver/shui2";

export const FormDatebox = ({ value, onChange, isError, portalTarget, automationId }) => {
	return (
		<Datebox
			customWidth={160}
			size="large"
			type={"filledDarker"}
			isError={isError}
			automationId={automationId}
			calendarAutomationId={`${automationId}-calendar`}
			floatingPortalTarget={portalTarget}
			onSelected={(date) => date && onChange(date.toISOString())}
			showInitialDate={!!value}
			defaultDate={value && new Date(value)}
		/>
	);
};
