import { z } from "zod";
import { caseNoSchemaError, hospitalIdSchemaError, nhsNoSchemaError } from "../../../../../globals/messages";

const caseNoSchema = z.nullable(z.string().max(100, { message: caseNoSchemaError }));

const nhsNoSchema = z.nullable(
	z.union([
		z
			.string()
			.length(10)
			.refine((val) => validateNHSNo(val), {
				message: nhsNoSchemaError,
			}),
		z.string().length(0),
	]),
);

const hospitalIdSchema = z.nullable(z.string().max(50, hospitalIdSchemaError));

const verificationStatusSchema = z.object({ label: z.string(), value: z.string() });

const nhsTraceDateSchema = z.nullable(z.union([z.string(), z.date()])).optional();

export const identifiersSchema = {
	caseNo: caseNoSchema,
	nhsNo: nhsNoSchema,
	hospitalId: hospitalIdSchema,
	verificationStatus: verificationStatusSchema,
	nhsTraceDate: nhsTraceDateSchema,
};

const validateNHSNo = (nhsNum) => {
	let checkDigit = 0;
	for (let i = 10; i > 1; i--) {
		checkDigit += Number(nhsNum[10 - i]) * i;
	}
	checkDigit = 11 - (checkDigit % 11);
	checkDigit = checkDigit === 11 ? 0 : checkDigit;

	return !(checkDigit === 10 || Number(nhsNum[9]) !== checkDigit);
};
