import { PatientCardPatientView } from "../../PatientCard/PatientCard";
import { useNextAppointmentWidget } from "../../../api/hooks/widgets/useNextAppointmentWidget";
import { Widget } from "../Widget/Widget";

export const NextAppointment = ({ patientId }) => {
	const nextAppointment = useNextAppointmentWidget(patientId);

	return (
		<Widget>
			<PatientCardPatientView ghost={nextAppointment.isLoading} appointment={{ ...nextAppointment.data, type: "OP" }} />
		</Widget>
	);
};
