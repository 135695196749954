import dayjs from "dayjs";
import { localFromUTC } from "../../../../../utils";

export const isTimeInvalid = (time, date, data) => {
	if (!time || !date || !data) return true;
	const is24hAvailable = data?.outpatient24h;
	if (is24hAvailable) return false;

	const processedTime = processTime(time, date);

	return !data?.availabilities?.some((availability) => isTimeInAvailability(processedTime, availability));
};

export const isTimeInAvailability = (time, availability) => {
	const availabilityStart = localFromUTC(availability?.start);
	const availabilityEnd = localFromUTC(availability?.end);
	const smallestEndTime = time.add(5, "minutes");

	return (
		availabilityStart <= time &&
		time <= availabilityEnd &&
		availabilityStart <= smallestEndTime &&
		smallestEndTime <= availabilityEnd
	);
};

export const getAvailabilityFromTime = (time, date, data) => {
	if (!time || !date || !data) return null;
	const processedTime = processTime(time, date);

	return data?.availabilities?.find((availability) => isTimeInAvailability(processedTime, availability));
};

export const processTime = (time, date) => {
	if (typeof time !== "string") return;
	const splitTime = time.split(":");
	return dayjs(date).set("minute", splitTime[1]).set("hour", splitTime[0]);
};

export const isAvailableTime = (time) => {
	const splitTime = time.split(":");
	return splitTime[1] === "00" || splitTime[1] === "15" || splitTime[1] === "30" || splitTime[1] === "45";
};
