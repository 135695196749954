export const addAppointmentNewDefaultValues = (
	date = new Date(new Date().setHours(0, 0, 0, 0)),
	time = "",
	duration = null,
) => ({
	patient: null,
	referralDate: new Date(new Date().setHours(0, 0, 0, 0)),
	referrer: null,
	caseHolder: null,
	diagnosis: null,
	date: date,
	time: time,
	duration: duration,
	isFaceToFace: true,
	contactType: null,
	site: null,
	purchaser: null,
	authCode: "",
	membershipNumber: "",
	notes: "",
});

export const addAppointmentFollowUpDefaultValues = (
	date = new Date(new Date().setHours(0, 0, 0, 0)),
	time = "",
	duration = null,
) => ({
	patient: null,
	episodeOfCare: null,
	date: date,
	time: time,
	duration: duration,
	isFaceToFace: true,
	contactType: null,
	site: null,
	purchaser: null,
	authCode: "",
	membershipNumber: "",
	notes: "",
});
