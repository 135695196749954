import dayjs from "dayjs";
import { convertEmptyStringToNull, localToUTC } from "../../utils";

export const transformAppointmentDataForAdd = (data, type) => {
	const isNewBooking = type !== "follow-up";
	return {
		patientId: data?.patient?.patientId,
		episodeOfCareId: isNewBooking ? null : data?.episodeOfCare?.episodeOfCareId,
		referralDate: isNewBooking ? data?.referralDate : null,
		referrerId: isNewBooking ? data?.referrer?.referrerId : null,
		leadCaseHolderId: isNewBooking ? data?.caseHolder?.clinicianId : null,
		diagnosisId: isNewBooking ? data?.diagnosis?.recordId : null,
		bookingStart: localToUTC(`${dayjs(data?.date).format("YYYY-MM-DD")} ${data?.time}`).toDate(),
		duration: data?.duration?.value,
		siteId: data?.site?.siteId,
		isFaceToFace: data?.isFaceToFace,
		contactTypeId: data?.contactType?.recordId ?? null,
		purchaserId: data?.purchaser?.purchaserId,
		authType: data?.purchaser?.ediAuthorisationType ?? null,
		authCode: data?.purchaser?.isSelfPay ? null : convertEmptyStringToNull(data?.authCode),
		insuranceMembershipNo: data?.purchaser?.isSelfPay ? null : convertEmptyStringToNull(data?.membershipNumber),
		notes: convertEmptyStringToNull(data?.notes),
	};
};
