import { Widget } from "../Widget/Widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./NewPatient.module.scss";

export function NewPatient() {
	return (
		<Widget automationId="new-patient-widget">
			<Widget.Link className={classes.container} to={"add-patient"}>
				<FontAwesomeIcon icon={faUserPlus} className={classes.icon} />
				<Widget.Title>New patient</Widget.Title>
			</Widget.Link>
		</Widget>
	);
}
