import { Widget } from "../Widget/Widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./AddAppointment.module.scss";

export function AddAppointment() {
	return (
		<Widget automationId="add-appointment-widget">
			<Widget.Link className={classes.container} to={"add-appointment"}>
				<FontAwesomeIcon icon={faCalendarPlus} className={classes.icon} />
				<Widget.Title>Add appointment</Widget.Title>
			</Widget.Link>
		</Widget>
	);
}
