import classes from "./AppointmentBody.module.scss";
import { PresenceBadge, Button, Ghost } from "@streets-heaver/shui2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPen,
	faCalendarDays,
	faClipboardList,
	faLinkSimple,
	faSyringe,
	faVideo,
	faUser,
	faCircleInfo,
	faTrash,
	faHospital,
} from "@shfortawesome/pro-light-svg-icons";
import { addToCalendarIndividualBooking, getStatusTextUpdate, localFromUTC, longDateFormat } from "../../utils";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { InfoRow } from "../InfoRow/InfoRow";
import { TimeDisplay } from "../TimeDisplay/TimeDisplay";
import { getStatusColour, useDataSources } from "@streetsheaver/compucore";
import { BulletList } from "../BulletList/BulletList";

export const AppointmentBody = ({ ghost = false, customHeader, hasActions = false, appointment, automationId }) => {
	const { currentDataSource } = useDataSources();

	const ghostIconButton = <Ghost width={24} height={24} />;
	const localStart = localFromUTC(appointment?.start);
	const localEnd = localFromUTC(appointment?.end);
	const isOutpatient = appointment?.type === "OP";
	const isDue = appointment?.status === "Booked";

	return (
		<div className={classes.AppointmentBody} data-testid={automationId}>
			<div className={classes.Header}>
				<p className={classes.Title}>{customHeader ?? "Booking details"}</p>
				{hasActions &&
					(ghost ? (
						<div className={classes.ActionContainer}>
							{isOutpatient && ghostIconButton}
							{isOutpatient && ghostIconButton}
							{isOutpatient && ghostIconButton}
							{ghostIconButton}
						</div>
					) : (
						<div className={classes.ActionContainer}>
							<Button
								size={"small"}
								type="subtle"
								icon={{ restIcon: faCalendarDays }}
								onClick={(e) => {
									e.preventDefault();
									addToCalendarIndividualBooking({ ...appointment });
								}}
								tooltipText="Add to calendar"
								hasBackground={true}
							/>
							{appointment?.videoConsultLink && (
								<Button
									size={"small"}
									type="subtle"
									icon={{ restIcon: faVideo }}
									navigation={{
										to: appointment?.videoConsultLink,
									}}
									tooltipText="Join appointment"
									hasBackground={true}
								/>
							)}
							{isOutpatient && isDue && (
								<Button
									size={"small"}
									type="subtle"
									icon={{ restIcon: faTrash }}
									navigation={{
										to: `/${currentDataSource.DataSourceId}/${ActionPaths.CancelAppointmentPreselected(
											appointment?.appointmentUniqueId,
										)}`,
									}}
									tooltipText="Cancel appointment"
									hasBackground={true}
								/>
							)}
							{isOutpatient && (
								<Button
									size={"small"}
									type="subtle"
									icon={{ restIcon: faPen }}
									navigation={{
										to: `/${currentDataSource.DataSourceId}/${ActionPaths.EditAppointmentPreselected(
											appointment?.appointmentUniqueId,
										)}`,
									}}
									tooltipText="Edit appointment"
									hasBackground={true}
								/>
							)}
						</div>
					))}
			</div>
			<InfoRow
				iconSize="large"
				icon={<PresenceBadge solidColour={getStatusColour(appointment?.status)} size={20} />}
				info={getStatusTextUpdate(appointment?.status)}
				ghost={ghost}
			/>
			<TimeDisplay type={appointment?.type} start={localStart} end={localEnd} ghost={ghost} />
			<InfoRow
				iconSize="small"
				icon={<FontAwesomeIcon icon={faCalendarDays} />}
				info={longDateFormat(localStart)}
				ghost={ghost}
			/>
			<InfoRow
				iconSize="small"
				icon={<FontAwesomeIcon icon={faClipboardList} />}
				info={<BulletList list={appointment?.orders} name="orders" />}
				ghost={ghost}
				hide={appointment?.orders?.length < 1}
				style={{ alignItems: "baseline" }}
			/>
			<InfoRow
				iconSize="small"
				icon={<FontAwesomeIcon icon={faSyringe} />}
				info={<BulletList list={appointment?.procedures} name="procedures" />}
				ghost={ghost}
				hide={appointment?.procedures?.length < 1}
				style={{ alignItems: "baseline" }}
			/>
			<InfoRow
				iconSize="small"
				icon={
					appointment?.isFaceToFace ? (
						<FontAwesomeIcon icon={faUser} />
					) : appointment?.videoConsultLink ? (
						<FontAwesomeIcon icon={faVideo} />
					) : (
						<FontAwesomeIcon icon={faCircleInfo} />
					)
				}
				info={
					appointment?.isFaceToFace
						? "Face to face"
						: appointment?.videoConsultLink
							? "Video consultation"
							: appointment?.contactType
				}
				ghost={ghost}
				hide={appointment?.type !== "OP"}
			/>
			<InfoRow
				iconSize="small"
				icon={<FontAwesomeIcon icon={faHospital} />}
				info={appointment?.site}
				ghost={ghost}
				hide={!appointment?.site}
			/>
			<InfoRow
				iconSize="small"
				icon={<FontAwesomeIcon icon={faLinkSimple} />}
				info={"Linked booking"}
				ghost={ghost}
				hide={!appointment?.isLinkedBooking || ghost}
			/>
		</div>
	);
};
