import classes from "./InfoRow.module.scss";
import { Ghost } from "@streets-heaver/shui2";
import clsx from "clsx";

export const InfoRow = ({
	icon,
	info,
	ghost = false,
	hide = false,
	colour = "one",
	iconSize = "medium",
	isBold = false,
	isCentred = false,
	style,
}) => {
	if (hide) return <></>;
	return ghost ? (
		<div className={clsx(classes.infoRow, isCentred && classes.centred)}>
			{icon && <Ghost height="16px" width="16px" borderRadius="999px" />}
			{info && <Ghost height="20px" width="200px" borderRadius="4px" />}
		</div>
	) : (
		<div className={clsx(classes.infoRow, classes[colour], isCentred && classes.centred)} style={style}>
			{icon && <div className={clsx(classes.icon, classes[iconSize])}>{icon}</div>}
			{info && <div className={clsx(classes.info, isBold && classes.bold)}>{info}</div>}
		</div>
	);
};
