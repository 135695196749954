import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@shfortawesome/pro-regular-svg-icons";
import classes from "./ClinicianSwitchButton.module.scss";

export const ClinicianSwitchButton = ({ clinicianName }) => {
	return (
		<span className={classes.clinicianSwitchButton} data-testid="clinicianSwitchButton">
			<span>{clinicianName}</span>
			<FontAwesomeIcon icon={faChevronDown} />
		</span>
	);
};
