import { Controller } from "react-hook-form";
import { Lookup } from "@streets-heaver/shui2";
import { useFormError } from "../../../utils";
import { FormField } from "../FormField/FormField";

export const SearchableLookup = ({
	control,
	label,
	lookupName,
	query,
	setSearchTerm,
	inline,
	isMandatory,
	disabled,
	helperText,
	rules,
	automationId,
}) => {
	const error = useFormError(lookupName);

	const controller = (
		<Controller
			name={lookupName}
			control={control}
			rules={rules}
			render={({ field: { value, onChange, ref } }) => (
				<Lookup
					selected={value}
					setSelected={onChange}
					onSearchTermChange={setSearchTerm}
					searchQueryData={query}
					reference={ref}
					idKey={"recordId"}
					isError={error?.length > 0}
					placeholder={inline ? label : undefined}
					disabled={disabled}
					automationId={`${automationId}-${label?.toLowerCase().replace(/\s/g, "")}`}
				/>
			)}
		/>
	);

	if (inline) return controller;

	return (
		<FormField label={label} name={lookupName} isMandatory={isMandatory} helperText={helperText}>
			{controller}
		</FormField>
	);
};
