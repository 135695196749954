import classes from "./PatientView.module.scss";
import { Outlet, useParams } from "react-router";
import { PatientBanner } from "@streets-heaver/shui2";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import {
	AbnormalResults,
	OrderStatus,
	PatientNotes,
	PatientOrders,
	UnseenReports,
	NoAccessPage,
	WidgetGrid,
	ContentSkeleton,
} from "../../components";
import { usePatientBanner } from "../../api/hooks/usePatientBanner";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { ScreenSize, useDataSources, useScreenSizeClass } from "@streetsheaver/compucore";
import { argbToRgba, formatNameAsReversed } from "../../utils";
import { NextAppointment } from "../../components/Widgets/NextAppointment/NextAppointment";

export default function PatientView() {
	const { currentClinician, userSecurityFlags } = useCurrentClinicians();
	const { patientId } = useParams();
	const patientBannerQuery = usePatientBanner(patientId);
	const patientData = patientBannerQuery?.data;
	const width = useScreenSizeClass();
	const { currentDataSource } = useDataSources();

	return (
		<div className={classes.PatientView} data-testid={"pagePatientView"}>
			{!currentClinician ? (
				<ContentSkeleton />
			) : (
				<>
					{!userSecurityFlags.includes("patientview") ? (
						<NoAccessPage />
					) : (
						<div className={classes.Body}>
							<Outlet />
							<PatientBanner
								isGhost={!patientBannerQuery.isSuccess}
								patientData={{
									id: patientData?.patientPrimaryId,
									idLink: `../patient/${patientData?.patientId}`,
									surname: patientData?.surname || "",
									addressLineOne: patientData?.addressLine1,
									isPatPortalRegistered: patientData?.isPatientPortalRegistered,
									...patientData,
									genderColour: patientData?.genderColour && argbToRgba(patientData?.genderColour),
									medicalRecordStatus: null,
								}}
								columnData={[
									[
										{ title: "Email", result: patientData?.email },
										{ title: "Main phone", result: patientData?.mainPhone },
										{ title: "Work phone", result: patientData?.workPhone },
										{ title: "Mobile phone", result: patientData?.mobilePhone },
									],
									[
										{ title: "Patient ID", result: patientData?.patientId },
										{ title: "NHS Number", result: patientData?.nhsNo },
										{ title: "Hospital ID", result: patientData?.hospitalId },
										{ title: "Case number", result: patientData?.caseNo },
										{ title: "Verification status", result: patientData?.verificationStatus },
									],
									[
										{ title: "Registered GP", result: patientData?.gp },
										{ title: "GP Practice", result: patientData?.gpPractice },
									],
									[
										{ title: "Sex (biological)", result: patientData?.sex },
										{ title: "Pronouns", result: patientData?.pronouns },
										{ title: "Gender", result: patientData?.gender },
									],
								]}
								alerts={patientData?.alerts}
								actions={{
									editPatient: { to: `../${ActionPaths.EditPatientPreselected(patientId)}` },
									medicalRecords: {
										to: `https://compucare-dev.streets-heaver.com/medical-records/${currentDataSource?.DataSourceId}/patient/${patientId}`,
									},
								}}
								automationId="patient-banner"
							/>
							<WidgetGrid align={width < ScreenSize.TabletLandscape ? "centre" : "left"}>
								<WidgetLayout width={width} data={patientData} userSecurityFlags={userSecurityFlags} />
							</WidgetGrid>
						</div>
					)}
				</>
			)}
		</div>
	);
}

const WidgetLayout = ({ width, data, userSecurityFlags }) => {
	const patientName = formatNameAsReversed(data?.forename, data?.surname, data?.title);

	if (width >= ScreenSize.Small)
		return (
			<>
				<WidgetGrid.Item positionX={1} positionY={1} width={2} height={4}>
					<NextAppointment patientId={data?.patientId} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={1} width={2} height={2}>
					<AbnormalResults
						hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
						patientId={data?.patientId}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={5} positionY={1} width={2} height={2}>
					<OrderStatus hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={7} positionY={1} width={1} height={1}>
					<UnseenReports hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={7} positionY={2} width={1} height={1}>
					<PatientNotes />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={3} width={4} height={2}>
					<PatientOrders
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
						patientName={patientName}
					/>
				</WidgetGrid.Item>
			</>
		);

	if (width >= ScreenSize.TabletLandscape)
		return (
			<>
				<WidgetGrid.Item positionX={1} positionY={1} width={2} height={2}>
					<NextAppointment patientId={data?.patientId} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={1} width={2} height={2}>
					<AbnormalResults
						hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
						patientId={data?.patientId}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={5} positionY={1} width={1} height={1}>
					<UnseenReports />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={5} positionY={2} width={1} height={1}>
					<PatientNotes />
				</WidgetGrid.Item>
			</>
		);

	if (width >= ScreenSize.TabletPortrait)
		return (
			<>
				<WidgetGrid.Item positionX={1} positionY={1} width={2} height={2}>
					<NextAppointment patientId={data?.patientId} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={1} width={2} height={2}>
					<AbnormalResults
						hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
						patientId={data?.patientId}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={1} positionY={3} width={2} height={2}>
					<OrderStatus />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={3} width={1} height={1}>
					<UnseenReports />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={4} positionY={3} width={1} height={1}>
					<PatientNotes />
				</WidgetGrid.Item>
			</>
		);

	return (
		<>
			<WidgetGrid.Item positionX={1} positionY={1} width={1} height={1}>
				<UnseenReports />
			</WidgetGrid.Item>
			<WidgetGrid.Item positionX={2} positionY={1} width={1} height={1}>
				<PatientNotes />
			</WidgetGrid.Item>
		</>
	);
};
