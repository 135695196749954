import { defaultDurationOptions } from "../../globals/defaultOptions";
import { convertEmptyStringToNull } from "../../utils";
import dayjs from "dayjs";

export const transformAppointmentDataForForm = (data) => ({
	...data,
	booking: {
		...data?.booking,
		type: "OP",
	},
	form: {
		...data?.form,
		arrived: data?.form?.arrived ? dayjs(data?.form?.arrived).format("HH:mm") : "",
		duration: defaultDurationOptions.find((duration) => duration.value === data?.form?.duration) ?? null,
		isFaceToFace: data?.form?.isFaceToFace,
		contactType: data?.form?.contactType ?? null,
		purchaser: data?.form?.purchaser?.purchaserId ? data?.form?.purchaser : null,
		authCode: data?.form?.authCode ?? "",
		membershipNumber: data?.form?.insuranceMembershipNo ?? "",
		notes: data?.form?.notes ?? "",
	},
});

export const transformAppointmentDataForEdit = (data, originalData) => ({
	bookingUniqueId: originalData?.form?.bookingUniqueId,
	patientId: originalData?.form?.patientId,
	duration: data?.duration?.value,
	arrived:
		data?.arrived && data?.arrived !== originalData?.form?.arrived
			? dayjs(`${dayjs().format("YYYY-MM-DD")} ${data?.arrived}`).toDate()
			: null,
	cancellationReasonId: null,
	outcomeId: null,
	isFaceToFace: data?.isFaceToFace,
	contactTypeId: !data?.isFaceToFace ? data?.contactType?.recordId : null,
	authType: originalData?.form?.purchaser?.ediAuthorisationType,
	authCode: convertEmptyStringToNull(data?.authCode),
	insuranceMembershipNo: convertEmptyStringToNull(data?.membershipNumber),
	notes: convertEmptyStringToNull(data?.notes),
});
