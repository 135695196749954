import dayjs from "dayjs";
import { z } from "zod";
import { dateOfBirthPastSchemaError, genderSchemaError } from "../../../../../globals/messages";

export const dateOfBirthSchema = z.nullable(
	z.union([z.string(), z.date()]).refine((value) => !dayjs(value).isAfter(dayjs(), "day"), {
		message: dateOfBirthPastSchemaError,
	}),
);

const genderSchema = z.object({ value: z.number() }, { message: genderSchemaError });

export const identitySchema = {
	dateOfBirth: dateOfBirthSchema,
	gender: genderSchema,
};
