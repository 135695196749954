import { useRegisteredGPsLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const RegisteredGPsLookup = ({ automationId, label = "Registered GP", name = "registeredGP" }) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useRegisteredGPsLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			query={query}
			automationId={automationId}
		/>
	);
};
