import { useState } from "react";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { AddPatientFormContent, DiscardChangesDialog, FormWrapper, NoAccessPage } from "../../components";
import { useCurrentClinicians, useCreatePatient } from "../../api/hooks";
import { addPatientFormDefaultValues, addPatientSchema } from "./AddPatientSchema";
import { transformPatientDataForAdd } from "./transformPatientData";

export const AddPatient = () => {
	const navigate = useNavigate();
	const { userSecurityFlags } = useCurrentClinicians();
	const { mutateAsync: createPatient, status: createStatus } = useCreatePatient();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: addPatientFormDefaultValues(),
		resolver: zodResolver(addPatientSchema),
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (data) => {
		toast
			.promise(createPatient(transformPatientDataForAdd(data)), {
				loading: { title: "Adding new patient", subtitle: `Creating patient` },
				success: {
					title: "Successfully created patient",
					subtitle: `Patient created`,
				},
				error: (e) => ({
					title: "Error creating patient",
					subtitle: e?.response?.data?.detail ?? `Failed creating patient. Please try again later.`,
				}),
			})
			.then(() => navigate(".."));
	};

	const onClose = () => navigate(-1);

	if (!userSecurityFlags.includes("patient-addpatient")) {
		return <NoAccessPage />;
	}

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Add new patient"}>
					<FormWrapper.Body>
						<AddPatientFormContent />
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick: methods.handleSubmit(onSubmit),
								children: "Add patient",
								automationId: "add-patient-submit",
								disabled: createStatus === "pending",
							},
							secondaryButton: {
								onClick: methods.formState.isDirty ? () => setIsConfirmDiscardDialogVisible(true) : () => onClose(),
								children: "Cancel",
								automationId: "add-patient-cancel",
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
