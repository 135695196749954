export const preferredWrittenMethodOptions = [
	{ value: "Post", label: "Post" },
	{ value: "Email", label: "Email" },
];

export const notificationMethodOptions = [
	{ value: "3", label: "Email", api: "Email" },
	{ value: "4", label: "Mobile", api: "Phone" },
	{ value: "1", label: "SMS to main phone", api: "SmsToLandLine" },
	{ value: "2", label: "SMS to mobile", api: "SmsToMobile" },
];

export const defaultDurationOptions = [
	{ label: "5 mins", value: 5 },
	{ label: "10 mins", value: 10 },
	{ label: "15 mins", value: 15 },
	{ label: "20 mins", value: 20 },
	{ label: "25 mins", value: 25 },
	{ label: "30 mins", value: 30 },
];

export const genderOptions = [
	{ label: "Male", value: 1 },
	{ label: "Female", value: 2 },
	{ label: "Indeterminate", value: 9 },
];

export const nhsVerifyStatusOptions = [
	{ label: "Number present and verified", value: "01" },
	{ label: "Number present but not traced", value: "02" },
	{ label: "Trace required", value: "03" },
	{ label: "Trace attempted - No match or multiple matches found", value: "04" },
	{ label: "Trace needs to be resolved - (NHS Number or patient detail conflict)", value: "05" },
	{ label: "Trace in progress", value: "06" },
	{ label: "Number not present and trace not required", value: "07" },
	{ label: "Trace postponed (baby under six weeks old)", value: "08" },
];
