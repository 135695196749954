import classes from "./ReviewResultsPanel.module.scss";
import { PatientHeader, RequestCard } from "../../components";
import { useOrderResults } from "../../api/hooks/useOrderResults";
import { useEffect, useRef } from "react";

export const ReviewResultsPanel = ({ patientId }) => {
	const { data: orderData, fetchNextPage: fetchMoreResults, isFetching, hasNextPage } = useOrderResults(patientId, 10);

	const endOfListRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((elements) => {
			elements.forEach((element) => {
				if (element.isIntersecting && !isFetching) {
					fetchMoreResults();
				}
			});
		});

		if (endOfListRef.current) observer.observe(endOfListRef.current);

		return () => {
			observer.disconnect();
		};
	}, [fetchMoreResults, isFetching]);

	return (
		<div className={classes.orderResults} data-testid="review-results">
			<PatientHeader patient={orderData?.pages[0]?.data} rounded={true} />
			<div className={classes.serviceRequests}>
				<div className={classes.subHeadings}>
					<p className={classes.title}>Service Requests</p>
					<p className={classes.title}>Completed Date</p>
				</div>
				<div className={classes.requestsList}>
					{orderData?.pages?.map((page) => {
						return page?.data?.serviceRequests?.map((serviceRequest, i) => {
							return <RequestCard key={`${patientId}-servicerequest-${i}`} isResultsCard order={serviceRequest} />;
						});
					})}
					{hasNextPage && <RequestCard isResultsCard ghost innerRef={endOfListRef} />}
				</div>
			</div>
		</div>
	);
};
