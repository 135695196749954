import { useEpisodeOfCaresLookup } from "../../../api/hooks";
import { useFormError } from "../../../utils";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { EpisodeOfCareLookup } from "@streetsheaver/compucore";

export const EpisodeOfCaresLookup = ({
	label = "Episode of care",
	name = "episodeOfCare",
	isMandatory,
	patient,
	automationId,
}) => {
	const form = useFormContext();
	const query = useEpisodeOfCaresLookup(patient?.patientId);

	const error = useFormError(name);

	return (
		<FormField label={label} isMandatory={isMandatory} name={name}>
			<Controller
				name={name}
				control={form.control}
				render={({ field: { value, onChange, ref } }) => (
					<EpisodeOfCareLookup
						selected={value}
						setSelected={onChange}
						dropdownMaxHeight={"calc(80vh - 190px)"}
						disabled={!patient}
						searchQueryData={query}
						reference={ref}
						isError={error?.length > 0}
						automationId={`${automationId}-eoc`}
					/>
				)}
			/>
		</FormField>
	);
};
