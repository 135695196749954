import { Field } from "@streets-heaver/shui2";
import classes from "./FormField.module.scss";
import { useFormError } from "../../../utils";

export const FormField = ({ label, isMandatory, name, children, helperText, action, automationId }) => {
	const error = useFormError(name);

	return (
		<Field
			helperText={helperText}
			label={label}
			isMandatory={isMandatory}
			isError={error.length > 0}
			errorText={error.map((message) => (
				<p className={classes.errorText} key={message}>
					{message}
				</p>
			))}
			automationId={automationId}
			{...action}
		>
			{children}
		</Field>
	);
};
