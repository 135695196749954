import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";

export const useCancelAppointment = () => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	return useMutation({
		mutationKey: ["cancelAppointment"],
		mutationFn: (data) => {
			return makeApiRequest(`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/bookings/cancel`, {
				method: "put",
				body: data,
			});
		},
	});
};
