export const getAppointmentType = (type) => {
	switch (type) {
		default:
		case "OP":
			return "Outpatient";
		case "OPSB":
		case "SB":
			return "Surgical";
		case "IP":
			return "Inpatient";
		case "PRIV":
			return "Private";
	}
};
