import { Field } from "@streets-heaver/shui2";
import { useSitesLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import classes from "./Lookup.module.scss";
import { siteSchemaError } from "../../../globals/messages";

export const SiteLookup = ({ label = "Site", name = "site", isMandatory, isViewOnly, helperText, automationId }) => {
	const { control, getValues } = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useSitesLookup(searchTerm);
	const value = getValues(name);
	const viewOnlyHelperText = "For reserved bookings, site is implied from the availability selected for the booking.";

	if (isViewOnly && value)
		return (
			<Field label={label} isMandatory={isMandatory} helperText={viewOnlyHelperText}>
				<div className={classes.selectedItem} data-testid={`${automationId}-site-preloaded-value`}>
					<p>{value.name}</p>
				</div>
			</Field>
		);

	return (
		<SearchableLookup
			control={control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			query={query}
			isMandatory={isMandatory}
			disabled={isViewOnly && !value}
			helperText={isViewOnly ? viewOnlyHelperText : helperText}
			{...(isMandatory && {
				rules: { validate: { required: (value) => value || siteSchemaError } },
			})}
			automationId={automationId}
		/>
	);
};
