import { useState } from "react";
import { Button, Textbox } from "@streets-heaver/shui2";
import { FormField } from "../../../FormField/FormField";

export const AdditionalPhoneNumbers = ({
	mainPhone,
	workPhone,
	mainPhoneField,
	workPhoneField,
	isMainPhoneMandatory = false,
	hasMainPhoneError,
	hasWorkPhoneError,
	automationId,
}) => {
	const [shouldShowMainPhone, setShouldShowMainPhone] = useState(mainPhoneField);
	const [shouldShowWorkPhone, setShouldShowWorkPhone] = useState(workPhoneField);
	const mainPhoneHasValue = mainPhoneField !== null && mainPhoneField !== "";
	const workPhoneHasValue = workPhoneField !== null && workPhoneField !== "";
	const isShowingMainPhone = isMainPhoneMandatory || shouldShowMainPhone || mainPhoneHasValue;
	const isShowingWorkPhone = shouldShowWorkPhone || workPhoneHasValue;

	return (
		<>
			{isShowingMainPhone && (
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Main phone" name={mainPhone.name} isMandatory={isMainPhoneMandatory}>
						<Textbox
							type={"filledDarker"}
							inputName={mainPhone.name}
							onChange={mainPhone.onChange}
							onBlur={mainPhone.onBlur}
							reference={mainPhone.ref}
							size="large"
							isError={hasMainPhoneError}
							automationId={`${automationId}-main`}
						/>
					</FormField>
				</div>
			)}
			{isShowingWorkPhone && (
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Work phone" name={workPhone.name}>
						<Textbox
							type={"filledDarker"}
							inputName={workPhone.name}
							onChange={workPhone.onChange}
							onBlur={workPhone.onBlur}
							reference={workPhone.ref}
							size="large"
							isError={hasWorkPhoneError}
							automationId={`${automationId}-work`}
						/>
					</FormField>
				</div>
			)}
			<div style={{ display: "flex", gap: "16px" }}>
				{!isShowingMainPhone && (
					<Button
						onClick={(e) => {
							e.preventDefault();
							setShouldShowMainPhone(true);
						}}
						size="small"
						automationId={`${automationId}-enable-main`}
					>
						Add main phone
					</Button>
				)}
				{!isShowingWorkPhone && (
					<Button
						onClick={(e) => {
							e.preventDefault();
							setShouldShowWorkPhone(true);
						}}
						size="small"
						automationId={`${automationId}-enable-work`}
					>
						Add work phone
					</Button>
				)}
			</div>
		</>
	);
};
