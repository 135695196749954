import { useRelationshipsLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const RelationshipsLookup = ({ label = "Relationship", name = "relationship", isMandatory, automationId }) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useRelationshipsLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			query={query}
			isMandatory={isMandatory}
			automationId={automationId}
		/>
	);
};
