import { PreviewPanel } from "@streetsheaver/compucore";
import { useSelectedBooking } from "../../utils";
import { PatientCardPreviewPanel } from "../PatientCard/PatientCard";

export const BookingPreviewPanel = () => {
	const { selectedBooking, setSelectedBooking } = useSelectedBooking();

	return (
		<PreviewPanel
			onBackButtonClick={
				selectedBooking
					? () => {
							setSelectedBooking(null);
						}
					: undefined
			}
			title={selectedBooking && `${selectedBooking.type} booking`}
		>
			{selectedBooking && (
				<PatientCardPreviewPanel
					bookingType={selectedBooking.type}
					bookingUniqueId={selectedBooking.id}
					overrideTab={selectedBooking.overrideTab}
				/>
			)}
		</PreviewPanel>
	);
};
