import { faNote } from "@shfortawesome/pro-duotone-svg-icons";
import { ComingSoon } from "../ComingSoon/ComingSoon";
import { Widget } from "../Widget/Widget";

export const PatientNotes = () => {
	return (
		<Widget>
			<ComingSoon title="Patient notes" icon={faNote} colour="var(--backgroundMarigold)" />
		</Widget>
	);
};
