import { Dialog } from "@streets-heaver/shui2";
import { useCurrentClinicians } from "../../api/hooks";
import classes from "./ClinicianSwitchModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@shfortawesome/pro-light-svg-icons";

export const ClinicianSwitchModal = ({ isVisible, onClose }) => {
	return (
		<Dialog
			visible={isVisible}
			onCloseButtonClick={onClose}
			onOutsideClick={onClose}
			header="Switch clinician"
			automationId="clinicianSwitchModal"
		>
			<ClinicianSwitchModalContents onClose={onClose} />
		</Dialog>
	);
};

const ClinicianSwitchModalContents = ({ onClose }) => {
	const { clinicians, currentClinician: currentClinicianId } = useCurrentClinicians();
	const currentClinician = clinicians?.find((clinician) => clinician.uniqueId === currentClinicianId);
	const cliniciansWithoutCurrent = clinicians?.filter((clinician) => clinician.uniqueId !== currentClinicianId);

	if (currentClinicianId)
		return (
			<div className={classes.clinicianSwitchModal}>
				<ClinicianItem clinician={currentClinician} onClose={onClose} />

				<div className={classes.cliniciansList}>
					{cliniciansWithoutCurrent.map((clinician) => (
						<ClinicianItem key={clinician.uniqueId} clinician={clinician} onClose={onClose} />
					))}
				</div>
			</div>
		);
};

const ClinicianItem = ({ clinician, onClose }) => {
	return (
		<button
			className={classes.clinicianItem}
			onClick={() => {
				clinician.onClick();
				onClose();
			}}
		>
			<div className={classes.checkContainer}>{clinician.isChecked && <FontAwesomeIcon icon={faCheck} />}</div>
			<p>{clinician.fullnameReverse}</p>
		</button>
	);
};
