import { ErrorDisplay } from "@streets-heaver/shui2";
import { Outlet } from "react-router";
import { usePageError } from "../../utils/hooks/usePageError";

export const Page = () => {
	const error = usePageError();

	if (error)
		return (
			<ErrorDisplay title={`${error?.title ?? "Unexpected Application Error"}`}>
				{(error?.detail || error?.message) ?? "Unknown error. Please contact an administrator."}
			</ErrorDisplay>
		);

	return <Outlet />;
};
