import { Textbox } from "@streets-heaver/shui2";
import { FormField } from "../../../FormField/FormField";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { useFormError } from "../../../../../utils";

export const Name = ({ title, surname, forename, automationId }) => (
	<div data-section id="name">
		<FormGroup title="Name">
			<FormField label="Title" name={title.name}>
				<div style={{ maxWidth: "320px" }}>
					<Textbox
						type={"filledDarker"}
						inputName={title.name}
						onChange={title.onChange}
						onBlur={title.onBlur}
						reference={title.ref}
						size="large"
						isError={useFormError(title.name).length > 0}
						automationId={`${automationId}-title`}
					/>
				</div>
			</FormField>
			<FormField label="Family name" isMandatory name={surname.name}>
				<Textbox
					type={"filledDarker"}
					inputName={surname.name}
					onChange={surname.onChange}
					onBlur={surname.onBlur}
					reference={surname.ref}
					size="large"
					isError={useFormError(surname.name).length > 0}
					automationId={`${automationId}-surname`}
				/>
			</FormField>
			<FormField label="Given name(s)" name={forename.name}>
				<Textbox
					type={"filledDarker"}
					inputName={forename.name}
					onChange={forename.onChange}
					onBlur={forename.onBlur}
					reference={forename.ref}
					size="large"
					isError={useFormError(forename.name).length > 0}
					automationId={`${automationId}-forename`}
				/>
			</FormField>
		</FormGroup>
	</div>
);
