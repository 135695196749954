import clsx from "clsx";
import classes from "./PatientCard.module.scss";
import { PatientHeader } from "../PatientHeader/PatientHeader";
import { AppointmentBody } from "../AppointmentBody/AppointmentBody";
import { AppointmentDetails } from "../AppointmentDetails/AppointmentDetails";
import { EpisodeOfCareCard } from "../EpisodeOfCareCard/EpisodeOfCareCard";
import { MessageBar } from "@streets-heaver/shui2";
import { isProduction } from "../../globals/constants";
import { ComingSoon } from "../Widgets/ComingSoon/ComingSoon";
import { faCalendarCheck } from "@shfortawesome/pro-duotone-svg-icons";
import { usePreviewPanelBooking } from "../../api/hooks";

export const PatientCardNextPatient = ({ ghost = false, appointment, automationId = "patientcard-nextpatient" }) => {
	return (
		<div className={classes.PatientCard} data-testid={automationId}>
			<PatientHeader ghost={ghost} patient={appointment} automationId={`${automationId}-header`} />
			<div className={clsx(classes.ScrollContainer, classes.TopMargin)}>
				<AppointmentBody
					ghost={ghost}
					customHeader={"Next appointment"}
					appointment={appointment}
					automationId={`${automationId}-body`}
				/>
				<AppointmentDetails
					ghost={ghost}
					appointment={{ ...appointment, type: "OP" }}
					automationId={`${automationId}-tablist`}
				/>
			</div>
		</div>
	);
};

export const PatientCardPreviewPanel = ({
	bookingType,
	bookingUniqueId,
	overrideTab,
	automationId = "patientcard-previewpanel",
}) => {
	const { isLoading: ghost, data: appointment } = usePreviewPanelBooking(bookingType.toLowerCase(), bookingUniqueId);

	return (
		<div className={classes.PatientCard} data-testid={automationId}>
			<div className={classes.ScrollContainer}>
				<PatientHeader ghost={ghost} patient={appointment} isRounded automationId={`${automationId}-header`} />
				<EpisodeOfCareCard ghost={ghost} data={appointment} automationId={`${automationId}-eoc`} />
				{bookingType === "OPSB" && <MessageBar body={"This booking is linked to a surgical booking."} />}
				{appointment?.isLinked && <MessageBar body={"This booking is linked to other bookings."} />}
				<AppointmentBody ghost={ghost} appointment={appointment} automationId={`${automationId}-body`} hasActions />
				<AppointmentDetails
					ghost={ghost}
					appointment={appointment}
					overrideTab={overrideTab}
					automationId={`${automationId}-tablist`}
				/>
			</div>
		</div>
	);
};

export const PatientCardPatientView = ({ ghost = false, appointment, automationId = "patientcard-patientview" }) => {
	if (isProduction) return <ComingSoon title="Next appointment for patient" icon={faCalendarCheck} />;

	return (
		<div className={classes.PatientCard} data-testid={automationId}>
			<div className={classes.ScrollContainer}>
				<div className={classes.AppointmentInfo}>
					<AppointmentBody
						ghost={ghost}
						appointment={appointment}
						customHeader="Next appointment"
						automationId={`${automationId}-body`}
						hasActions
					/>
				</div>
				<AppointmentDetails ghost={ghost} appointment={appointment} automationId={`${automationId}-tablist`} />
			</div>
		</div>
	);
};
