import { PatientItem, EpisodeOfCareItem, BookingItem } from "@streetsheaver/compucore";
import classes from "./AppointmentForm.module.scss";
import { CancellationReasonsLookup } from "../lookups/CancellationReasonsLookup";
import { OutcomesLookup } from "../lookups/OutcomesLookup";
import { Accordion } from "@streets-heaver/shui2";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@shfortawesome/pro-regular-svg-icons";

export const CancelAppointmentFormContent = ({ patient, episodeOfCare, booking, linkedBookings, automationId }) => {
	const [isAccordionOpen, setIsAccordionOpen] = useState(true);
	return (
		<div className={classes.appointmentForm}>
			<div className={classes.header}>
				<div className={classes.patient}>
					<PatientItem data={patient} automationId={`${automationId}-patientItem`} patientLinkPrefix="../patient/" />
				</div>
				<EpisodeOfCareItem data={episodeOfCare} automationId={`${automationId}-eocItem`} />
				<BookingItem data={{ ...booking, type: "OP" }} automationId={`${automationId}-bookingItem`} />
			</div>
			{linkedBookings?.length > 0 && (
				<Accordion title={<AccordionTitle />} isOpen={isAccordionOpen} setIsOpen={setIsAccordionOpen}>
					<div className={classes.bookings}>
						{linkedBookings?.map((booking) => (
							<BookingItem key={booking?.bookingUniqueId} data={{ ...booking, type: "OP" }} hasNoHeader />
						))}
					</div>
				</Accordion>
			)}
			<CancellationReasonsLookup isMandatory automationId={automationId} />
			<OutcomesLookup isMandatory automationId={automationId} />
		</div>
	);
};

const AccordionTitle = () => (
	<div className={classes.accordionTitle}>
		<FontAwesomeIcon icon={faWarning} className={classes.icon} />
		<p className={classes.text}>Linked bookings</p>
	</div>
);
