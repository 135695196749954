export const formatNameAsInitials = (given, family) => {
	if (given && family) {
		return (
			given
				.split(" ")
				.map((name) => name.slice(0, 1))
				.join("") +
			family
				.split(" ")
				.map((name) => name.slice(0, 1))
				.join("")
		).toUpperCase();
	} else if (family || given) {
		return family
			.split(" ")
			.map((name) => name.slice(0, 1))
			.join("")
			.toUpperCase();
	} else {
		return "-";
	}
};

export const formatNameAsCasual = (given, family) => `${given} ${family}`;

export const formatNameAsReversed = (given, family, title) => {
	if (family) {
		return `${family.toUpperCase()}${given ? `, ${given}` : ""}${title ? ` (${title})` : ""}`;
	} else {
		return "-";
	}
};

export const formatObjectAsReversed = (name) => {
	const given = name?.forename;
	const family = name?.surname;
	const title = name?.title;
	return formatNameAsReversed(given, family, title);
};
