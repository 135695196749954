import { Button, Card } from "@streets-heaver/shui2";
import classes from "./FormWrapper.module.scss";
import { ScreenSize, useScreenSizeClass } from "@streetsheaver/compucore";
import { Outlet } from "react-router";
import { FloatingActionButton } from "../FloatingActionButton/FloatingActionButton";
import { faArrowUp } from "@shfortawesome/pro-light-svg-icons";
import { useRef, useState } from "react";

const FormWrapper = ({ header, hasScrollToTopButton, children, ...rest }) => {
	const pageRef = useRef(null);
	const [actionButtonPosition, setActionButtonPosition] = useState(-100);

	return (
		<div
			className={classes.page}
			ref={pageRef}
			onScroll={(e) => {
				const distanceToBottom =
					e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop;
				if (e.currentTarget.scrollTop < 32) {
					setActionButtonPosition(-100);
				} else if (distanceToBottom < 100) {
					setActionButtonPosition(150);
				} else {
					setActionButtonPosition(40);
				}
			}}
		>
			<Outlet />
			<Card className={classes.pageCard} border={false}>
				<h2 className={classes.header}>{header}</h2>
				{children}
				<ButtonBar buttons={rest?.buttons} />
			</Card>
			{hasScrollToTopButton && (
				<FloatingActionButton
					icon={{ restIcon: faArrowUp }}
					onClick={(e) => {
						e.preventDefault();
						pageRef.current.scrollTo({ top: 0, behavior: "smooth" });
					}}
					bottom={actionButtonPosition}
				/>
			)}
		</div>
	);
};

const ButtonBar = ({ buttons }) => {
	const width = useScreenSizeClass();
	return (
		<div className={classes.buttonBar}>
			{buttons?.tertiaryButton && (
				<Button takeContainerWidth={width < ScreenSize.TabletPortrait} {...buttons?.tertiaryButton} />
			)}
			<div className={classes.rightButtons}>
				{buttons?.primaryButton && (
					<Button type="primary" takeContainerWidth={width < ScreenSize.TabletPortrait} {...buttons?.primaryButton} />
				)}
				{buttons?.secondaryButton && (
					<Button takeContainerWidth={width < ScreenSize.TabletPortrait} {...buttons?.secondaryButton} />
				)}
			</div>
		</div>
	);
};

FormWrapper.ButtonBar = ButtonBar;

const Body = ({ children }) => {
	return <div className={classes.body}>{children}</div>;
};

FormWrapper.Body = Body;

export { FormWrapper };
