import { Button } from "@streets-heaver/shui2";
import classes from "./FloatingActionButton.module.scss";

export const FloatingActionButton = ({ icon, children, onClick, navigation, bottom = 40 }) => {
	return (
		<div className={classes.floatingActionButtonWrapper} style={{ bottom: bottom }}>
			<Button className={classes.floatingActionButton} icon={icon} onClick={onClick} navigation={navigation}>
				{children}
			</Button>
		</div>
	);
};
