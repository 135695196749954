import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faGrid2 as falGrid2 } from "@shfortawesome/pro-light-svg-icons";
import { faGrid2 as farGrid2, faMagnifyingGlass } from "@shfortawesome/pro-regular-svg-icons";
import { faGrid2 as fasGrid2 } from "@shfortawesome/pro-solid-svg-icons";
import { faGrid2 as fadGrid2 } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./NavBarAppsButton.module.scss";
import { useState } from "react";
import {
	autoUpdate,
	flip,
	FloatingFocusManager,
	offset,
	shift,
	size,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
} from "@floating-ui/react";
import clsx from "clsx";
import { NavLink, Textbox } from "@streets-heaver/shui2";
import { AnimatePresence, motion } from "framer-motion";

export const NavBarAppsButton = ({ apps = [] }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	const { refs, floatingStyles, context } = useFloating({
		placement: "right-end",
		open: isMenuOpen,
		onOpenChange: (isOpen) => {
			setSearchTerm("");
			setIsMenuOpen(isOpen);
		},
		middleware: [
			offset({ crossAxis: 60, mainAxis: 16 }),
			flip(),
			shift(),
			size({
				apply({ availableHeight, elements }) {
					elements.floating.style.maxHeight = `${availableHeight}px`;
				},
			}),
		],
		whileElementsMounted: autoUpdate,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useDismiss(context)]);

	const isSearching = searchTerm !== "";
	const filteredApps = apps.filter((app) =>
		isSearching ? app.title.toLowerCase().includes(searchTerm.toLowerCase()) : !app.isActivity,
	);

	return (
		<>
			<button
				className={clsx(classes.appsButton, isMenuOpen && classes.selected)}
				ref={refs.setReference}
				{...getReferenceProps()}
				data-testid="navBarAppButton"
			>
				<FontAwesomeIcon icon={falGrid2} className={classes.restIcon} />
				<FontAwesomeIcon icon={farGrid2} className={classes.hoverIcon} />
				<FontAwesomeIcon icon={fasGrid2} className={classes.pressedIcon} />
				<FontAwesomeIcon icon={fadGrid2} className={classes.selectedIcon} />
			</button>
			<AnimatePresence>
				{isMenuOpen && (
					<FloatingFocusManager context={context} modal={false}>
						<motion.div
							className={classes.appsMenu}
							{...getFloatingProps}
							ref={refs.setFloating}
							style={{ ...floatingStyles }}
							transition={{ bounce: 0, type: "linear", damping: 0, duration: 0.1 }}
							initial={{ opacity: 0.01 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0.01 }}
						>
							<Textbox
								type="filledDarker"
								placeholder="Search"
								icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								autofocus
							/>
							<div className={clsx(classes.appsList, isSearching && classes.rows)}>
								{filteredApps.map((app) => (
									<NavLink key={app.title} to={app.to}>
										<button
											className={classes.app}
											onClick={() => {
												app.onClick && app.onClick();
												setIsMenuOpen(false);
												setSearchTerm("");
											}}
										>
											<FontAwesomeIcon className={classes.appIcon} icon={app.icon} />
											<p className={classes.title}>{app.title}</p>
											{isSearching && (
												<>
													<p className={classes.actionText}>{app.actionText}</p>
													<FontAwesomeIcon className={classes.chevron} icon={faChevronRight} />
												</>
											)}
										</button>
									</NavLink>
								))}
								{filteredApps.length === 0 && isSearching && (
									<p>
										No app with the name <span className={classes.boldText}>{searchTerm}</span> was found
									</p>
								)}
							</div>
						</motion.div>
					</FloatingFocusManager>
				)}
			</AnimatePresence>
		</>
	);
};
