import { useFormContext, useWatch, Controller } from "react-hook-form";
import { Switch } from "@streets-heaver/shui2/inputs";
import { notificationMethodOptions } from "../../../../../globals/defaultOptions";
import { FormField } from "../../../FormField/FormField";
import { Radio } from "../../../../Radio/Radio";

export const PreferredNotification = ({
	notificationMethodConfirmed,
	preferredNotificationMethod,
	mobilePhone,
	email,
	mainPhone,
	automationId,
}) => {
	const showPreferredNotificationMethodField = useWatch({ name: notificationMethodConfirmed.name });
	const form = useFormContext();

	return (
		<>
			<FormField label="Notification method confirmed?">
				<Controller
					control={form.control}
					name={notificationMethodConfirmed.name}
					render={({ field: { onChange, value } }) => (
						<Switch
							checked={value}
							onChange={onChange}
							label={value ? "Yes" : "No"}
							automationId={`${automationId}-notif-confirm`}
						/>
					)}
				/>
			</FormField>

			{showPreferredNotificationMethodField && (
				<FormField name={preferredNotificationMethod.name} label="Preferred notification method" isMandatory>
					<Controller
						name={preferredNotificationMethod.name}
						control={form.control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Radio
								items={notificationMethodOptions}
								value={value?.value}
								onChange={(e) => {
									onChange(e);
									form.trigger([preferredNotificationMethod.name, mobilePhone.name, email.name, mainPhone.name]);
								}}
								isError={error}
								automationId={`${automationId}-notif-preferred`}
							/>
						)}
					/>
				</FormField>
			)}
		</>
	);
};
