export const getEocStatusColour = (isOpen) => (isOpen ? "var(--foregroundLightGreen)" : "var(--Compucare)");

export const getBookingColour = (type, past = false) => {
	switch (type?.toLowerCase()) {
		case "opsb":
		case "sb":
		case "surgical":
			return past ? "var(--secondaryAppColourAlt)" : "var(--foregroundRedThree)";
		case "op":
		case "outpatient":
		default:
			return past ? "var(--backgroundBrandTwoAlternate)" : "var(--Compucare)";
		case "sus":
		case "suspension":
			return "var(--foregroundDisabled)";
		case "res":
		case "reservation":
		case "priv":
			return "var(--foregroundFour)";
	}
};

export const argbToRgba = (colour) => {
	if (colour.length !== 9) {
		return colour;
	}

	return `#${colour.substring(3)}${colour.substring(1, 3)}`;
};
