import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";

export const usePatientForEdit = (patientId, enabled = true) => {
	const { currentDataSource } = useDataSources();

	const query = useQuery({
		queryKey: ["patientForEdit", patientId],
		queryFn: async () => {
			const data = await makeApiRequest(`${currentDataSource?.DataSourceId}/edit/load/${patientId}`);
			if (data?.request?.response) throw new Error("Getting patient details Failed");
			return data;
		},
		gcTime: 0,
		retry: false,
		enabled: !!currentDataSource && !!patientId && enabled,
	});

	return query;
};
