import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";

export const useReportTemplatesLookup = (isSurgical, searchText) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "report-templates", isSurgical, searchText],
		queryFn: async ({ pageParam }) => {
			const data = await getReportTemplates(
				currentDataSource?.DataSourceId,
				currentClinician,
				pageParam,
				12,
				isSurgical,
				searchText,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});

	return {
		...query,
		data: query?.data?.pages?.flatMap((page) => page?.data),
	};
};

const getReportTemplates = async (dataSourceId, clinicianId, skip = 0, take, isSurgical, searchText) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/lookup/reporttemplates?isSurgicalList=${isSurgical}&skip=${skip}&take=${
			take + 1
		}${searchText !== "" ? `&searchtext=${encodeURIComponent(searchText)}` : ""}`,
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
