import { Widget } from "../Widget/Widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./PrintLists.module.scss";

export function PrintLists() {
	return (
		<Widget automationId="print-lists-widget">
			<Widget.Link className={classes.container} to={"print/clinic"}>
				<FontAwesomeIcon icon={faPrint} className={classes.icon} />
				<Widget.Title>Print lists</Widget.Title>
			</Widget.Link>
		</Widget>
	);
}
