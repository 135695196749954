import { getIfEmptyOrNull } from "../../../../../utils/convertEmptyStringToNull";
import {
	preferredNotificationErrorText,
	emailErrorText,
	addressLine1ErrorText,
	postcodeErrorText,
	mobileErrorText,
	mobileSmsErrorText,
	mainSmsErrorText,
} from "../../../../../globals/messages";

export const preferredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.contact.primary.notificationMethodConfirmed &&
			getIfEmptyOrNull(val.contact.primary.preferredNotificationMethod)
		),
	args: {
		message: preferredNotificationErrorText,
		path: ["contact", "primary", "preferredNotificationMethod"],
	},
};

export const emailRequiredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			(val.contact.primary.preferredWrittenMethod === "Email" ||
				val.contact.primary.preferredNotificationMethod?.label === "Email") &&
			getIfEmptyOrNull(val.contact.primary.email)
		),
	args: {
		message: emailErrorText,
		path: ["contact", "primary", "email"],
	},
};

export const addressLine1RequiredWrittenMethodRefinement = {
	condition: (val) => {
		return !(
			val.contact.primary.preferredWrittenMethod === "Post" &&
			getIfEmptyOrNull(val.contact.primary.address.addressLine1)
		);
	},
	args: {
		message: addressLine1ErrorText,
		path: ["contact", "primary", "address", "addressLine1"],
	},
};

export const postcodeRequiredWrittenMethodRefinement = {
	condition: (val) =>
		!(val.contact.primary.preferredWrittenMethod === "Post" && getIfEmptyOrNull(val.contact.primary.address.postcode)),
	args: {
		message: postcodeErrorText,
		path: ["contact", "primary", "address", "postcode"],
	},
};

export const mobilePhoneRequiredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.contact.primary.preferredNotificationMethod?.label === "Mobile" &&
			val.contact.primary.notificationMethodConfirmed &&
			getIfEmptyOrNull(val.contact.primary.mobilePhone)
		),
	args: {
		message: mobileErrorText,
		path: ["contact", "primary", "mobilePhone"],
	},
};

export const mainPhoneRequiredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.contact.primary.preferredNotificationMethod?.label === "SMS to main phone" &&
			val.contact.primary.notificationMethodConfirmed &&
			getIfEmptyOrNull(val.contact.primary.mainPhone)
		),
	args: {
		message: mainSmsErrorText,
		path: ["contact", "primary", "mainPhone"],
	},
};

export const mobilePhoneRequiredSMSNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.contact.primary.preferredNotificationMethod?.label === "SMS to mobile" &&
			val.contact.primary.notificationMethodConfirmed &&
			getIfEmptyOrNull(val.contact.primary.mobilePhone)
		),
	args: {
		message: mobileSmsErrorText,
		path: ["contact", "primary", "mobilePhone"],
	},
};
