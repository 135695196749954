import { useState } from "react";
import classes from "./Dashboard.module.scss";
import {
	AppointmentCalendar,
	ContentSkeleton,
	NextPatientCard,
	UpcomingAppointments,
	AddAppointment,
	NewPatient,
	PrintLists,
	WidgetGrid,
	Widget,
} from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";
import { Outlet } from "react-router";
import clsx from "clsx";
import { useSelectedBooking } from "../../utils/hooks/useSelectedBooking";
import { BookingPreviewPanel } from "../../components/BookingPreviewPanel/BookingPreviewPanel";

export default function Dashboard() {
	const width = useContentSizeClass();

	const { currentClinician, userSecurityFlags } = useCurrentClinicians();

	const [dayRange, setDayRange] = useState(parseInt(localStorage.getItem("compucare.calendarDayRange")) || 7);
	const [startTime, setStartTime] = useState(parseInt(localStorage.getItem("compucare.calendarStartTime")) || 8);
	const [timescale, setTimescale] = useState(parseInt(localStorage.getItem("compucare.calendarInterval")) || 2);

	const { selectedBooking, setSelectedBooking } = useSelectedBooking();

	return (
		<div
			className={clsx(classes.dashboard, width < ScreenSize.TabletLandscape && classes.mobile)}
			data-testid={"pageDashboard"}
		>
			<BookingPreviewPanel />

			{!currentClinician ? (
				<ContentSkeleton />
			) : (
				<>
					<Outlet />
					<div className={clsx(classes.content)}>
						<div className={clsx(classes.topRow, width < ScreenSize.Small && classes.tablet)}>
							<WidgetGrid align={width < ScreenSize.TabletLandscape ? "centre" : "left"}>
								{width < ScreenSize.TabletPortrait ? (
									<>
										<WidgetGrid.Item positionX={1} positionY={1} width={2} height={3}>
											<NextPatientCard
												hasPermission={userSecurityFlags?.includes(`dashboard-nextpatientappointment`)}
											/>
										</WidgetGrid.Item>
										<WidgetGrid.Item positionX={1} positionY={4} width={1} height={1}>
											<AddAppointment />
										</WidgetGrid.Item>
										<WidgetGrid.Item positionX={2} positionY={4} width={1} height={1}>
											<PrintLists />
										</WidgetGrid.Item>
										<WidgetGrid.Item positionX={1} positionY={5} width={1} height={1}>
											<NewPatient />
										</WidgetGrid.Item>
									</>
								) : (
									<>
										<WidgetGrid.Item positionX={1} positionY={1} width={2} height={3}>
											<NextPatientCard
												hasPermission={userSecurityFlags?.includes(`dashboard-nextpatientappointment`)}
											/>
										</WidgetGrid.Item>
										<WidgetGrid.Item positionX={3} positionY={1} width={1} height={1}>
											<AddAppointment />
										</WidgetGrid.Item>
										<WidgetGrid.Item positionX={4} positionY={1} width={1} height={1}>
											<PrintLists />
										</WidgetGrid.Item>
										<WidgetGrid.Item positionX={3} positionY={2} width={1} height={1}>
											<NewPatient />
										</WidgetGrid.Item>
									</>
								)}
							</WidgetGrid>
							<Widget className={classes.appointmentWidget} hasBorder>
								<UpcomingAppointments
									setSelectedBooking={setSelectedBooking}
									selectedBookingId={selectedBooking?.id}
									hasPermission={userSecurityFlags?.includes(`dashboard-upcomingappointment`)}
								/>
							</Widget>
						</div>
						{width >= ScreenSize.TabletLandscape && (
							<Widget className={classes.schedulerWidget} hasBorder>
								<AppointmentCalendar
									timescale={timescale}
									setTimescale={setTimescale}
									startTime={startTime}
									setStartTime={setStartTime}
									dayRange={dayRange}
									setDayRange={setDayRange}
									setSelectedBooking={setSelectedBooking}
									hasPermission={userSecurityFlags?.includes(`scheduler`)}
								/>
							</Widget>
						)}
					</div>
				</>
			)}
		</div>
	);
}
