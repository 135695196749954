import { z } from "zod";

const practiceObject = z.object({
	practiceId: z.number(),
	name: z.string(),
	addressLine1: z.nullable(z.string()).optional(),
	addressLine2: z.nullable(z.string()).optional(),
	addressLine3: z.nullable(z.string()).optional(),
	townCity: z.nullable(z.string()).optional(),
	county: z.nullable(z.string()).optional(),
	postcode: z.nullable(z.string()).optional(),
	organisationCode: z.nullable(z.string()).optional(),
});

const referrerObject = z.object({ referrerId: z.number(), name: z.string() });

const registeredGPSchema = z.nullable(referrerObject);

const gpPracticeSchema = z.nullable(practiceObject);

const registeredOpticianSchema = z.nullable(referrerObject);

const opticianPracticeSchema = z.nullable(practiceObject);

export const sharedSchema = {
	registeredGP: registeredGPSchema,
	gpPractice: gpPracticeSchema,
	registeredOptician: registeredOpticianSchema,
	opticianPractice: opticianPracticeSchema,
};
