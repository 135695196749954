import { createContext, useMemo, useState } from "react";

export const SelectedBookingContext = createContext();

export const SelectedBookingProvider = ({ children }) => {
	const [booking, setBooking] = useState();
	const providerOptions = useMemo(
		() => ({
			selectedBooking: booking,
			setSelectedBooking: setBooking,
		}),
		[booking, setBooking],
	);

	return <SelectedBookingContext.Provider value={providerOptions}>{children}</SelectedBookingContext.Provider>;
};
