import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../../globals/constants";

export const useAbnormalResults = (patientId, take) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["abnormalResults", currentClinician, patientId, take],
		queryFn: async ({ pageParam }) => {
			const data = await getAbnormalResults(
				currentDataSource?.DataSourceId,
				currentClinician,
				patientId,
				pageParam,
				take,
			);
			if (data?.request?.response) throw new Error("Getting abnormal results for patient failed");
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId && !isNaN(take),
	});

	const flatData = useMemo(() => query.data?.pages?.flatMap((page) => page?.data), [query?.data]);

	return { ...query, data: flatData };
};

const getAbnormalResults = async (dataSourceId, clinicianId, patientId, skip = 0, take) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/patient/${patientId}/widget/abnormalresults?skip=${skip}&take=${
			take + 1
		}`,
		{},
		"v2",
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
