import { nameSchema } from "../../components/FormComponents/PatientForms/Sections/Name/nameSchema";
import { identitySchema } from "../../components/FormComponents/PatientForms/Sections/Identity/identitySchema";
import { contactSchema } from "../../components/FormComponents/PatientForms/Sections/Contact/contactSchema";
import {
	addressLine1RequiredWrittenMethodRefinement,
	emailRequiredNotificationMethodRefinement,
	mainPhoneRequiredNotificationMethodRefinement,
	mobilePhoneRequiredNotificationMethodRefinement,
	mobilePhoneRequiredSMSNotificationMethodRefinement,
	postcodeRequiredWrittenMethodRefinement,
	preferredNotificationMethodRefinement,
} from "../../components/FormComponents/PatientForms/Sections/Contact/contactRefinements";
import { identifiersSchema } from "../../components/FormComponents/PatientForms/Sections/Identifiers/identifiersSchema";
import { nextOfKinSchema } from "../../components/FormComponents/PatientForms/Sections/NextOfKin/nextOfKinSchema";
import { sharedSchema } from "../../components/FormComponents/PatientForms/Sections/Shared/sharedSchema";
import { z } from "zod";
import { nhsVerifyStatusOptions } from "../../globals/defaultOptions";
import { verificationStatusNhsNumberRefinement } from "../../components/FormComponents/PatientForms/Sections/Identifiers/identifiersRefinements";

export const addPatientSchema = z
	.object({
		name: z.object({ primary: z.object(nameSchema) }),
		identity: z.object(identitySchema),
		contact: z.object({ primary: z.object(contactSchema) }),
		sharedDetails: z.object(sharedSchema),
		identifiers: z.object(identifiersSchema),
		nextOfKin: nextOfKinSchema.optional(),
	})
	.refine(preferredNotificationMethodRefinement.condition, preferredNotificationMethodRefinement.args)
	.refine(emailRequiredNotificationMethodRefinement.condition, emailRequiredNotificationMethodRefinement.args)
	.refine(addressLine1RequiredWrittenMethodRefinement.condition, addressLine1RequiredWrittenMethodRefinement.args)
	.refine(postcodeRequiredWrittenMethodRefinement.condition, postcodeRequiredWrittenMethodRefinement.args)
	.refine(
		mobilePhoneRequiredNotificationMethodRefinement.condition,
		mobilePhoneRequiredNotificationMethodRefinement.args,
	)
	.refine(
		mobilePhoneRequiredSMSNotificationMethodRefinement.condition,
		mobilePhoneRequiredSMSNotificationMethodRefinement.args,
	)
	.refine(mainPhoneRequiredNotificationMethodRefinement.condition, mainPhoneRequiredNotificationMethodRefinement.args)
	.refine(verificationStatusNhsNumberRefinement.condition, verificationStatusNhsNumberRefinement.args);

export const addPatientFormDefaultValues = () => ({
	name: {
		primary: {
			title: "",
			forename: "",
			surname: "",
		},
	},
	identity: {
		dateOfBirth: null,
		gender: null,
	},
	contact: {
		primary: {
			address: {
				addressLine1: "",
				addressLine2: "",
				addressLine3: "",
				addressLine4: "",
				addressLine5: "",
				postcode: "",
				country: null,
			},
			email: "",
			mobilePhone: "",
			workPhone: "",
			mainPhone: "",
			notificationMethodConfirmed: false,
			preferredNotificationMethod: null,
			preferredWrittenMethod: "Post",
		},
	},
	nextOfKin: [],
	sharedDetails: {
		registeredGP: null,
		gpPractice: null,
		registeredOptician: null,
		opticianPractice: null,
	},
	identifiers: {
		caseNo: "",
		nhsNo: "",
		hospitalId: "",
		verificationStatus: nhsVerifyStatusOptions[2],
		nhsTraceDate: null,
	},
});
