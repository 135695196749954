import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { PatientsLookup } from "../../../lookups/PatientsLookup";
import { EpisodeOfCaresLookup } from "../../../lookups/EpisodeOfCaresLookup";

export const FollowUpPatient = ({ automationId }) => {
	const { resetField, watch } = useFormContext();

	const patientName = "patient";
	const episodeOfCareName = "episodeOfCare";

	const patient = watch(patientName);

	useEffect(() => {
		if (!patient) {
			resetField(episodeOfCareName, { defaultValue: null });
		}
	}, [resetField, patient]);

	return (
		<FormGroup>
			<PatientsLookup isMandatory name={patientName} automationId={automationId} />
			<EpisodeOfCaresLookup isMandatory patient={patient} name={episodeOfCareName} automationId={automationId} />
		</FormGroup>
	);
};
