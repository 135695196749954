import { z } from "zod";

export const printModalSchema = z.object({
	startDate: z.date(),
	endDate: z.date(),
	template: z.object({ reportTemplateId: z.union([z.number(), z.string()]) }, { message: "Template is required" }),
});

export const printModalValues = () => ({
	startDate: new Date(),
	endDate: new Date(),
	template: null,
});
