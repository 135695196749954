import { useEffect, useState } from "react";
import { ErrorDisplay, Spinner } from "@streets-heaver/shui2";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { FormWrapper, PatientBookingSelection } from "../../components";
import { CancelAppointmentForm } from "./CancelAppointmentForm";
import { useNavigate, useParams } from "react-router";
import { useAppointmentForCancel } from "../../api/hooks";
import classes from "./CancelAppointment.module.scss";

export const CancelAppointment = () => {
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const navigate = useNavigate();
	const { bookingId } = useParams();
	const { data, isLoading, error } = useAppointmentForCancel(bookingId, !!bookingId);

	useEffect(() => {
		if (!selectedPatient) {
			setSelectedBooking(null);
		}
	}, [selectedPatient]);

	if (!bookingId)
		return (
			<FormWrapper header={"Cancel an appointment"}>
				<FormWrapper.Body>
					<PatientBookingSelection
						selectedBooking={selectedBooking}
						setSelectedBooking={setSelectedBooking}
						selectedPatient={selectedPatient}
						setSelectedPatient={setSelectedPatient}
					/>
				</FormWrapper.Body>
				<FormWrapper.ButtonBar
					buttons={{
						tertiaryButton: {
							navigation: {
								to: selectedBooking
									? `../${ActionPaths.EditAppointmentPreselected(selectedBooking?.bookingUniqueId)}`
									: selectedPatient
										? `../${ActionPaths.EditPatientPreselected(selectedPatient?.uniqueId)}`
										: undefined,
							},
							children: selectedBooking ? "Edit appointment" : "Edit patient",
							disabled: !selectedPatient,
						},
						secondaryButton: { onClick: () => navigate(-1), children: "Cancel" },
						primaryButton: {
							navigation: { to: `../${ActionPaths.CancelAppointmentPreselected(selectedBooking?.bookingUniqueId)}` },
							children: "Cancel appointment",
							disabled: !selectedBooking,
						},
					}}
				/>
			</FormWrapper>
		);

	if (error)
		return (
			<FormWrapper>
				<FormWrapper.Body>
					<div className={classes.formContent}>
						<ErrorDisplay title="Booking not found">Unable to find this booking.</ErrorDisplay>
					</div>
				</FormWrapper.Body>
			</FormWrapper>
		);

	if (bookingId && data && !isLoading) return <CancelAppointmentForm data={data} />;

	return (
		<FormWrapper>
			<FormWrapper.Body>
				<div className={classes.formContent}>
					<Spinner size="huge" text="Loading appointment details" />
				</div>
			</FormWrapper.Body>
		</FormWrapper>
	);
};
