import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Spinner } from "@streets-heaver/shui2";
import { FormWrapper, PatientSelection, NoAccessPage } from "../../components";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { useCurrentClinicians, usePatientForEdit } from "../../api/hooks";
import classes from "./EditPatient.module.scss";
import { EditPatientForm } from "./EditPatientForm";
import { transformPatientDataForForm } from "../AddPatient/transformPatientData";

export const EditPatient = () => {
	const { patientId } = useParams();
	const navigate = useNavigate();
	const { userSecurityFlags } = useCurrentClinicians();
	const { data, isLoading } = usePatientForEdit(patientId, !!patientId);
	const selectedPatient = patientId && data;
	const [formattedData, setFormattedData] = useState();

	useEffect(() => {
		if (data) setFormattedData(transformPatientDataForForm(data));
	}, [data]);

	if (!userSecurityFlags.includes("patient-editpatient")) {
		return <NoAccessPage />;
	}

	if (!selectedPatient && !patientId)
		return (
			<FormWrapper header={"Edit Patient"}>
				<FormWrapper.Body>
					<PatientSelection
						selectedPatient={selectedPatient}
						setSelectedPatient={(patient) =>
							navigate(patient?.patientId ? `./${patient.patientId}` : `../${ActionPaths.EditPatient}`)
						}
						automationId={"edit-patient"}
					/>
				</FormWrapper.Body>
				<FormWrapper.ButtonBar
					buttons={{
						secondaryButton: {
							onClick: () => {
								navigate(-2);
							},
							children: "Cancel",
						},
					}}
				/>
			</FormWrapper>
		);

	if (patientId && formattedData && !isLoading) return <EditPatientForm data={formattedData} patientId={patientId} />;

	return (
		<FormWrapper>
			<FormWrapper.Body>
				<div className={classes.formContent}>
					<Spinner size="huge" text="Loading patient details" />
				</div>
			</FormWrapper.Body>
		</FormWrapper>
	);
};
