import { useState } from "react";
import { CompucareFrame, ScreenSize, useScreenSizeClass } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { faUserDoctor } from "@shfortawesome/pro-light-svg-icons";
import { faUserDoctor as farUserDoctor } from "@shfortawesome/pro-regular-svg-icons";
import { faUserDoctor as fasUserDoctor } from "@shfortawesome/pro-solid-svg-icons";
import { faUserDoctor as fadUserDoctor } from "@shfortawesome/pro-duotone-svg-icons";
import { useLocation } from "react-router";
import { useBreadcrumbs } from "./useBreadcrumbs";
import { pages } from "./pages";
import { apps } from "./apps";
import { ClinicianSwitchModal, NavBarAppsButton } from "../../components";

const Frame = ({ children }) => {
	const compucareURL =
		window.location.host === "clinician.streets-heaver.com"
			? "https://compucare.streets-heaver.com/home"
			: "https://compucare-dev.streets-heaver.com/home";

	const { currentClinician, clinicians, userSecurityFlags } = useCurrentClinicians();
	const [isMobileVisible, setIsMobileVisible] = useState(false);
	const [isClinicianSwitchModalVisible, setIsClinicianSwitchModalVisible] = useState(false);

	const location = useLocation();
	const pathname = location.pathname;
	const [_, datasource, ...pagepath] = pathname.split("/");

	const width = useScreenSizeClass();

	const breadcrumbs = useBreadcrumbs(pathname, datasource, () => setIsClinicianSwitchModalVisible(true));

	return (
		<CompucareFrame
			hasActionsBar={width > ScreenSize.TabletPortrait}
			breadcrumbs={breadcrumbs}
			compucareTo={compucareURL}
			mobilePanel={{
				visible: isMobileVisible,
				setVisible: (e) => setIsMobileVisible(e),
			}}
			menuItems={pages(pagepath[0], datasource, userSecurityFlags, setIsMobileVisible)}
			{...(width <= ScreenSize.TabletPortrait && {
				accounts: currentClinician && clinicians?.length > 0 ? clinicians : undefined,
			})}
			accountsMenuInfo={{
				title: currentClinician
					? clinicians?.find((clinician) => clinician.uniqueId === currentClinician)?.fullnameReverse
					: "Clinician",
				truncate: true,
				icon: {
					restIcon: faUserDoctor,
					hoverIcon: farUserDoctor,
					pressedIcon: fasUserDoctor,
					selectedIcon: fadUserDoctor,
				},
				...(width > ScreenSize.TabletPortrait && {
					onClick: () => {
						setIsClinicianSwitchModalVisible(true);
					},
				}),
			}}
			enabledDefaultMenuItems={{ apps: false, patients: false, people: false }}
			customHomeIcon={<NavBarAppsButton apps={apps(userSecurityFlags)} />}
		>
			{children}
			<ClinicianSwitchModal
				isVisible={isClinicianSwitchModalVisible}
				onClose={() => {
					setIsClinicianSwitchModalVisible(false);
					setIsMobileVisible(false);
				}}
			/>
		</CompucareFrame>
	);
};

export default Frame;
