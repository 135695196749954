import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor, faCalendarDays, faClipboardList, faUsersRectangle } from "@shfortawesome/pro-duotone-svg-icons";
import { useCurrentClinicians } from "../../api/hooks";
import { ClinicianSwitchButton } from "../../components";

const iconStyle = { height: 20, width: 20, padding: "2px", marginRight: 4, color: "var(--appColour)" };

export const useBreadcrumbs = (pathname, dataSourceId, onClinicianSwitchClick) => {
	const { currentClinician, clinicians } = useCurrentClinicians();
	const clinicianName = clinicians?.find((clinician) => clinician.uniqueId === currentClinician)?.fullnameReverse;

	const pageName = pathname?.split("/")?.[2];
	const arrayOfBreadcrumbs = [
		{
			name: "Clinician",
			icon: <FontAwesomeIcon icon={faUserDoctor} style={iconStyle} />,
			navigation: { to: "/" + dataSourceId },
		},
		{
			name: { clinicianName },
			onClick: onClinicianSwitchClick,
			render: <>{currentClinician && <ClinicianSwitchButton clinicianName={clinicianName} />}</>,
		},
	];

	switch (pageName) {
		case "scheduler":
			arrayOfBreadcrumbs.push({
				name: "Scheduler",
				icon: <FontAwesomeIcon icon={faCalendarDays} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "scheduler" },
			});
			break;
		case "mypatients":
			arrayOfBreadcrumbs.push({
				name: "My patients",
				icon: <FontAwesomeIcon icon={faUsersRectangle} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "mypatients" },
			});
			break;
		case "orders":
			arrayOfBreadcrumbs.push({
				name: "Orders",
				icon: <FontAwesomeIcon icon={faClipboardList} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "orders" },
			});
			break;
		default:
			break;
	}

	return arrayOfBreadcrumbs;
};
