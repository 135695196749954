import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";

export const useCreatePatient = () => {
	const { currentDataSource } = useDataSources();

	return useMutation({
		mutationKey: ["createPatient"],
		mutationFn: (data) => {
			return makeApiRequest(`${currentDataSource?.DataSourceId}/create`, {
				method: "post",
				body: data,
			});
		},
	});
};
