import { z } from "zod";
import {
	forenameSchemaError,
	surnameSchemaLengthError,
	surnameSchemaRequiredError,
	titleSchemaError,
} from "../../../../../globals/messages";

const titleSchema = z.nullable(z.string().max(25, { message: titleSchemaError })).optional();

const forenameSchema = z.nullable(z.string().max(200, { message: forenameSchemaError }));

const surnameSchema = z
	.string()
	.min(1, { message: surnameSchemaRequiredError })
	.max(200, { message: surnameSchemaLengthError });

export const nameSchema = { title: titleSchema, forename: forenameSchema, surname: surnameSchema };
